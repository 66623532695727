export function getBaseUrls() {
    const hostname = window.location.hostname;

    const environments = {
        dev: ['dev', 'localhost'],
        production: [] // Default case
    };

    // Identify the environment
    let env = 'production'; // Default to production
   if (environments.dev.some(envName => hostname.includes(envName))) {
        env = 'dev';
    }

    // Define base URLs
    const config = {
        dev: {
            baseURL: process.env.VUE_APP_API_BASE_URL_DEV,
            inboxBaseURL: process.env.VUE_APP_INBOX_API_BASE_URL_DEV,
            integrationBaseURL: process.env.VUE_APP_INTEGRATIONS_API_BASE_URL_DEV,
            teamsBaseURL: process.env.VUE_APP_TEAMS_API_BASE_URL_DEV,
            logEventsBaseURL: process.env.VUE_APP_EVENT_HISTORY_URL_DEV
        },
        production: {
            baseURL: process.env.VUE_APP_API_BASE_URL,
            inboxBaseURL: process.env.VUE_APP_INBOX_API_BASE_URL,
            integrationBaseURL: process.env.VUE_APP_INTEGRATIONS_API_BASE_URL,
            teamsBaseURL: process.env.VUE_APP_TEAMS_API_BASE_URL,
            logEventsBaseURL: process.env.VUE_APP_EVENT_HISTORY_URL
        }
    };

    // Return the appropriate environment configuration
    return config[env];
}
