<template>
  <popper @close:popper="(search = ''), (selectedCategory = '')" :class="type == 'note' ? '' : 'ms-auto'" @click="$event.stopPropagation()">
    <i
      class="fa cursor-pointer"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      data-bs-trigger="hover"
      title="Available Values"
      :class="type == 'note' ? 'fa-regular text-sm fa-1x fa-tag mt-2' : 'fa-regular fa-tag fa-2x '"
      :style="type == 'note' ? 'font-weight: normal;' : ''"></i>
    <template #content="{ close }">
      <div class="popover-content bg-white rounded-3 shadow inbox-nav-manage-options" ref="popoverContent">
        <div class="col-12 container-fluid p-0">
          <ul class="list-unstyled text-xs p-1 mb-0 popli">
            <li class="bg-light text-sm d-flex align-items-center justify-content-between">
              <span class="p-2"> Available Values </span>
              <i class="fa p-2 cursor-pointer fa-times" @click="(search = ''), close()"></i>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div class="form-group w-100 mx-1">
                <i class="fa fa-search position-absolute" style="top: 9px; left: 18px"></i>
                <input type="text" v-model="search" class="form-control py-1 ps-4" placeholder="Search" />
              </div>
            </li>
          </ul>
          <ul class="list-unstyled text-sm p-1 mb-2 popli" style="max-height: 350px; min-width: 250px; overflow-y: scroll; scrollbar-width: auto !important">
            <template v-if="search">
              <template v-if="searchPlaceholder()?.length">
                <li @click="$emit('appendPlaceHolder', placeHolder.value), (search = ''), close()" v-for="(placeHolder, index) in searchPlaceholder()" :key="index" class="mx-1 cursor-pointer">
                  <div class="p-2 d-flex align-items-center justify-content-between hover-highlight">
                    <a class="text-col-navlink text-sm text-decoration-none text-nowrap text-ellipsis" href="javascript:void(0)">{{ placeHolder.category + ' / ' + placeHolder.label }}</a>
                  </div>
                </li>
              </template>
              <li v-else class="mx-1 cursor-pointer">
                <div class="p-2 d-flex align-items-center justify-content-center hover-highlight">
                  <a class="text-col-navlink text-sm text-decoration-none text-nowrap text-ellipsis" href="javascript:void(0)">No Result Found</a>
                </div>
              </li>
            </template>
            <template v-else-if="!selectedCategory">
              <li @click="selectedCategory = category" v-for="(placeHolder, category) in placeHolder" :key="category" class="mx-1 cursor-pointer">
                <div class="p-2 d-flex align-items-center justify-content-between hover-highlight">
                  <a
                    class="text-col-navlink text-sm text-decoration-none text-nowrap text-ellipsis"
                    href="javascript:void(0)"
                    :class="category.toLowerCase() === 'ai' ? 'text-uppercase' : 'text-capitalize'"
                    >{{ category }}</a
                  >
                  <i class="fa fa-chevron-right text-xs ms-2"></i>
                </div>
              </li>
            </template>
            <template v-else>
              <li class="bg-light text-sm d-flex align-items-center border-bottom">
                <i class="fa p-2 cursor-pointer fa-arrow-left text-primary fs-normal" @click="selectedCategory = ''"></i>
                <span class="p-2" :class="selectedCategory.toLowerCase() === 'ai' ? 'text-uppercase' : 'text-capitalize'">
                  {{ selectedCategory }}
                </span>
              </li>
              <li @click="$emit('appendPlaceHolder', placeHolder.value), (search = ''), close()" v-for="(placeHolder, index) in placeHolder[selectedCategory]" :key="index" class="mx-1 cursor-pointer">
                <div class="p-2 d-flex align-items-center justify-content-between hover-highlight">
                  <a class="text-col-navlink text-sm text-decoration-none text-nowrap text-ellipsis" href="javascript:void(0)">{{ placeHolder.label }}</a>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </template>
  </popper>
</template>
<script>
  import placeholdersjson from '@/assets/js/placeholdersjson'
  export default {
    name: 'PlaceHolders',
    props: ['type', 'nodes', 'selectedData'],
    data() {
      return {
        search: '',
        selectedCategory: '',
        placeHolder: placeholdersjson
      };
    },
    $emits: ['appendPlaceHolder'],
    methods: {
      searchPlaceholder() {
        let searchResults = [];
        for (const key in this.placeHolder) {
          const element = this.placeHolder[key];
          let results = element.filter((el) => el.label.toLowerCase().includes(this.search.toLowerCase()));
          // console.log('results ', results);
          searchResults = [...searchResults, ...results];
        }
        return searchResults;
      },
      appendNumberedPlaceHolders() {
        // console.log('this.selectedData ', this.selectedData)
        let index = this.nodes.findIndex((el) => el.id == this.selectedData.id)
        if(index == -1) {
          index = this.nodes.findIndex((el) => el.id == this.$store.state.selectedNodeId)
        }
        let aiNodes = this.nodes.filter((el,i ) => i < index && !el.data.add && el.data.type == "addNewAction" && ['llama','claude', 'gemini', 'chatgpt'].includes(el.name))
        if(this.nodes[index]?.data?.branchId) {
          let branchesToIncludes = [this.nodes[index]?.data?.branchId]
          const findBranchesToIncludes = (branchId) => {
            let isBranchLabelNode = this.nodes?.filter((el) => el.data?.branchId == branchId).find((el) => el.data.isBranchLabelNode)
            if(isBranchLabelNode) {
              let findParent = this.nodes.find((el) => el.id == isBranchLabelNode.data.parentId)
              if(findParent?.data?.branchId) {
                findBranchesToIncludes(findParent?.data?.branchId)
                branchesToIncludes.push(findParent?.data?.branchId)
              }
            }
          }
          findBranchesToIncludes(this.nodes[index]?.data?.branchId)
          aiNodes = aiNodes?.filter((el) =>  el.data?.branchId == null || branchesToIncludes.includes(el.data?.branchId))
        }
        if(aiNodes?.length) {
          this.placeHolder['AI'] = []
        } else {
          delete  this.placeHolder['AI'];
        }
        // console.log('aiNodes ', aiNodes)
        aiNodes.forEach(element => {
          if(element?.name == 'chatgpt') {
            const label = element?.data?.serialNo ? `#${element?.data?.serialNo} OpenAI Response` :  'OpenAI Response';
            const value = element?.data?.serialNo ? `{{ai.openai_response.${element?.data?.serialNo}}}` :  '{{ai.openai_response}}';
            this.placeHolder['AI'].push({ label:label, value: value, category: 'Ai', name:'chatgpt' })
          }
          if(element?.name == 'gemini') {
            const label = element?.data?.serialNo ? `#${element?.data?.serialNo} Gemini Response` :  'Gemini Response';
            const value = element?.data?.serialNo ? `{{ai.gemini_response.${element?.data?.serialNo}}}` :  '{{ai.gemini_response}}';
            this.placeHolder['AI'].push({ label: label, value: value, category: 'Ai', name:'gemini' })
          }
          if(element?.name == 'claude') {
            const label = element?.data?.serialNo ? `#${element?.data?.serialNo} Claude Response` :  'Claude Response';
            const value = element?.data?.serialNo ? `{{ai.claude_response.${element?.data?.serialNo}}}` :  '{{ai.claude_response}}';
            this.placeHolder['AI'].push( { label: label, value: value, category: 'Ai', name:'claude' })
          }
          if(element?.name == 'llama') {
            const label = element?.data?.serialNo ? `#${element?.data?.serialNo} Llama Response` :  'Llama Response';
            const value = element?.data?.serialNo ? `{{ai.llama_response.${element?.data?.serialNo}}}` :  '{{ai.llama_response}}';
            this.placeHolder['AI'].push({ label: label, value:value, category: 'Ai', name:'llama' })
          }
        });
        if(this.placeHolder['AI']) {
          this.placeHolder['AI'] = this.placeHolder['AI'].sort((a, b) => a.label.localeCompare(b.label))
        }
        this.placeHolder = Object.keys(this.placeHolder).sort().reduce(
          (obj, key) => { 
            obj[key] = this.placeHolder[key]; 
            return obj;
          }, 
          {}
        );
      }
    },
    mounted() {
      this.appendNumberedPlaceHolders()
    },
    watch:{
      'selectedData': {
        deep:true,
        handler() {
          this.appendNumberedPlaceHolders()
        }
      }
    }
  };
</script>

<style scoped>
  ::v-deep .popper .hover-highlight:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
    border-radius: 4px !important;
  }
</style>
