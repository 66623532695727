<template>
  <div class="position-fixed overflow-y-scroll shadow w-100 h-100 bg-light" v-if="selectedLog"
    style="max-width:512px; right:0; z-index: 999; top:0">
    <div class="bg-log-background d-flex justify-content-between p-4">
      <div class="text-white">
        <div class="h5 mb-1">Event Details</div>
        <div class="text-sm"><span class="text-log">All event related details can be found here</span></div>
      </div>
      <div class="text-white">
        <i class="fa fa-1x fa-times" @click="selectedLog = null"></i>
      </div>
    </div>
    <div class="px-3">

      <div class="py-3 border-bottom">
        <label class="history-label mb-2">Contact</label>
        <div>{{ selectedLog?.threadId }}</div>
        <div class="text-xs">{{selectedLog?.sourceEmail}}</div>
      </div>
      <div class="py-3 border-bottom">
        <label class="history-label mb-1">Action</label>
        <div class="text-xs">
          {{selectedLog?.metaData.actionUid && !selectedLog?.metaData?.triggerName  ? nodes.find((el) => el.id == selectedLog?.metaData.actionUid )?.data?.title : getActionLabel(selectedLog)}}
        </div>
      </div>
      <div class="py-3 border-bottom">
        <label class="history-label mb-2">Event Status</label>
        <div class="text-xs">
          <button :class="executionStatus(selectedLog?.state)"
            class="rounded-pill text-col-navlink text-xs btn-sm py-1 px-3 text-bold mb-0 no-shadow me-1 text-col-status border-0 badge-success text-success rounded-pill text-col-navlink text-xs btn-sm py-1 px-3 text-bold mb-0 no-shadow me-1 text-col-status border-0 cursor-auto">

            {{ selectedLog?.state == 0 ? 'Finished' :  selectedLog?.state > 0 ? 'In progress' : 'Failed'}}
          </button>
        </div>
      </div>
      <div class="py-3 border-bottom" v-if="selectedLog?.metaData?.triggerName">
        <label class="history-label mb-1">Added From</label>
        <div class="text-xs">Trigger</div>
        <div class="text-xs">Name:
          {{nodes.find((el) => el.id == selectedLog?.metaData.actionUid )?.data?.title || findWorkflowByName(selectedLog?.metaData?.triggerName)?.label}}
        </div>
      </div>
      <div class="py-3 border-bottom" v-if="selectedLog?.metaData?.branchCondition">
        <label class="history-label mb-2">Condition Traced</label>
        <div style="background-color: #E5E6EA;" class="p-3 mb-3">
          <div class="my-2 history-label fs-14">Name: {{selectedLog?.log}}</div>
          <template v-for="condition, idx in selectedLog?.metaData?.conditions?.conditions" :key="idx">
            <div class="history-label fs-14 mb-2">Segment: {{idx + 1}} </div>
            <div class="fs-14" style="white-space: break-spaces; word-break: break-word;">
              <div class="card rounded-4 mb-3 overflow-hidden">
                <div class="d-flex" style="background-color: #F2F3F5;">
                  <div class="col-5 p-3">Condition</div>
                  <div class="col-4 p-3">Value</div>
                  <div class="col-3 p-3">Result</div>
                </div>
                <div class="d-flex">
                    <div class="col-5 p-3">{{condition.type + ' ' + condition.operator}}</div>
                    <div class="col-4 p-3">{{ condition?.value }}</div>
                    <div class="col-3 p-3">
                      <button :class="condition?.result ? 'badge-success text-success' : 'badge-danger text-danger'" class="rounded-pill text-col-navlink text-xs btn-sm py-1 px-3 text-bold mb-0 no-shadow me-1 text-col-status border-0 cursor-auto"> {{ condition?.result }}</button>
                    </div>
                </div>
              </div>
            </div>
          </template>
        </div>

        <label class="history-label mb-2">Original Condition</label>
        <div style="background-color: #E5E6EA;" class="p-3 mb-3">
          <div class="my-2 history-label fs-14">Name: {{getOriginalCondtions(selectedLog?.metaData?.actionId)?.branch_name}}</div>
          <div class="my-2 history-label fs-14">Conditions</div>
          <template v-for="condition, idx in getOriginalCondtions(selectedLog?.metaData?.actionId)?.condition?.conditions" :key="idx">
            <div class="history-label fs-14 mb-2">Segment: {{idx + 1}} </div>
            <div class="fs-14" style="white-space: break-spaces; word-break: break-word;">
              <div class="card rounded-4 mb-2 overflow-hidden">
                
                <div class="d-flex">
                    <div class="col-8 p-3">{{condition.type + ' ' + condition.operator + ' ' + condition?.value}}</div>
                    <div class="col-4 p-3">{{  }}</div>
                </div>
              </div>
            </div>
            <div class="">
              <button class="btn rounded-0 btn-secondary btn-xs mx-auto mb-1" v-if="idx !== getOriginalCondtions(selectedLog?.metaData?.actionId)?.condition?.conditions?.length - 1">{{selectedLog?.metaData?.conditions?.type}}</button>
            </div>
          </template>
        </div>
      </div>
      <div class="py-3 border-bottom" v-else>
        <label class="history-label mb-1">Message</label>
        <div class="text-xs">{{selectedLog?.log}}</div>
      </div>
      <div class="py-3 border-bottom" v-if="selectedLog?.metaData?.note">
        <label class="history-label mb-1">Note</label>
        <div class="text-xs" style="white-space: break-spaces; word-break: break-word;">{{selectedLog?.metaData?.note}}
        </div>
      </div>
      <div class="py-3 border-bottom mb-3">
        <label class="history-label mb-2">Executed On</label>
        <div class="text-xs">{{getTimeStamp(selectedLog?.metaData.timestamp, 'MMMM Do YYYY, h:mm:ss a', false)}}</div>
      </div>
    </div>
  </div>
  <div class="bg-light mx-auto border border-top border-1"
    style="height: calc(100vh - 100px); transition: 0.5s; z-index: 999; right: 0">
    <!---->
    <div class="container-xl pt-5">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h1 class="display-7 h2">Execution {{activeTab == 'executionlogs' ? 'Logs' : 'History'}}</h1>
          <p class="text-sm" v-if="activeTab  == 'executionlogs'">View a history of all executions performed by this
            Automation</p>
          <p class="text-sm" v-else>View a history of all the contacts who have entered this Automation</p>
        </div>
        <div>
          <!-- Add any buttons or actions here -->
        </div>
      </div>

      <div class="mt-4 d-flex p-4 align-items-center shadow-executionlog rounded-2"
        v-if="activeTab == 'executionlogs' && execution_history_email">
        <button class="btn p-0 d-flex align-items-center justify-content-center  me-3"
          style="height: 3rem; font-size: 14px; width: 3rem;     border-radius: 50% !important;" :style="{ backgroundColor: getEmailColor(execution_history_email),
            color: isColorLight(getEmailColor(execution_history_email)) ? 'black' : 'white'
           }">
          {{getUserInitials(execution_history_email)}}
        </button>
        <span>
          <div class="execution_history_email_label mb-1 d-flex">
            <div class="me-2">{{ execution_history_email }} </div>
            <div style="font-size: 17px;"><i
                @click="openConversationinNewTab(getHistory.find((el) => (el?.eventRunId == execution_history_id) || (el?.threadId == logs_conversation_filter)))"
                class="fa fa-external-link cursor-pointer" aria-hidden="true"></i></div>
          </div>
          <div class="execution_history_email_desc">{{ execution_history_id ? 'Execution History' : 'Contact History'}}
            for {{ execution_history_email }}</div>
        </span>
      </div>

      <div class="mt-4">
        <div class="d-flex justify-content-start align-items-center">
          <div>
            <!-- Filters or other elements can go here -->
          </div>
          <div class="d-flex">
            <template v-if="activeTab == 'executionhistory'">


              <VueDatePicker style="min-width: 290px;" ref="dp" class="me-2" v-model="historyDurationFilterDateLocal"
                :enable-time-picker="false" range multi-calendars :min-date="minDate" :max-date="maxDate">
                <template #clear-icon>
                  <!-- <img class="input-slot-image" src="/logo.png" @click="clear" /> -->
                </template>
                <template #action-extra>
                  <button type="button" class="btn btn-sm  btn-outline-primary me-2 mb-1 border-primary py-0"
                    @click="setFilterDate('historyDurationFilterDate', 'thisweek')">This Week</button>
                  <button type="button" class="btn btn-sm  btn-outline-primary me-2 mb-1 border-primary py-0"
                    @click="setFilterDate('historyDurationFilterDate', 'lastweek')">Last Week</button>
                  <button type="button" class="btn btn-sm  btn-outline-primary me-2 mb-1 border-primary py-0"
                    @click="setFilterDate('historyDurationFilterDate', 'last7days')">Last 7 days</button>
                  <button type="button" class="btn btn-sm  btn-outline-primary me-2 mb-1 border-primary py-0"
                    @click="setFilterDate('historyDurationFilterDate', 'thismonth')">This Month</button>
                  <button type="button" class="btn btn-sm  btn-outline-primary me-2 mb-1 border-primary py-0"
                    @click="setFilterDate('historyDurationFilterDate', 'last15days')">Last 15 Days</button>
                  <button type="button" class="btn btn-sm  btn-outline-primary me-2 mb-1 border-primary py-0"
                    @click="setFilterDate('historyDurationFilterDate', 'last30days')">Last 30 days</button>
                </template>
                <template #action-buttons>
                  <button type="button" class="btn btn-outline-secondary me-2  px-1 py-0"
                    @click="closePicker">Cancel</button>
                  <button type="button" class="btn btn-sm btn-primary px-1 py-0" @click="selectDate">Select</button>
                </template>
              </VueDatePicker>

              <select v-model="history_event_filter" class="form-select me-2" id="filter-action">
                <option value="">All Events</option>
                <option :value="workflow?.name" v-for="workflow,i in triggerWorkflowOption" :key="i">{{workflow?.label}}
                </option>
              </select>

              <select v-model="history_contact_filter" class="form-select me-2" id="filter-event">
                <option value="">All Contact</option>
                <option :key="i" :value="contact" v-for="contact, i in contactFilters">{{contact}}</option>
              </select>
            </template>
            <template v-else>


              <VueDatePicker style="min-width: 290px;" ref="logdp" class="me-2" v-model="logsDurationFilterDateLocal"
                :enable-time-picker="false" range multi-calendars :min-date="minDate" :max-date="maxDate">
                <template #clear-icon>
                  <!-- <img class="input-slot-image" src="/logo.png" @click="clear" /> -->
                </template>
                <template #action-extra>
                  <button type="button" class="btn btn-sm  btn-outline-primary me-2 mb-1 border-primary py-0"
                    @click="setFilterDate('logsDurationFilterDate', 'thisweek')">This Week</button>
                  <button type="button" class="btn btn-sm  btn-outline-primary me-2 mb-1 border-primary py-0"
                    @click="setFilterDate('logsDurationFilterDate', 'lastweek')">Last Week</button>
                  <button type="button" class="btn btn-sm  btn-outline-primary me-2 mb-1 border-primary py-0"
                    @click="setFilterDate('logsDurationFilterDate', 'last7days')">Last 7 days</button>
                  <button type="button" class="btn btn-sm  btn-outline-primary me-2 mb-1 border-primary py-0"
                    @click="setFilterDate('logsDurationFilterDate', 'thismonth')">This Month</button>
                  <button type="button" class="btn btn-sm  btn-outline-primary me-2 mb-1 border-primary py-0"
                    @click="setFilterDate('logsDurationFilterDate', 'last15days')">Last 15 Days</button>
                  <button type="button" class="btn btn-sm  btn-outline-primary me-2 mb-1 border-primary py-0"
                    @click="setFilterDate('logsDurationFilterDate', 'last30days')">Last 30 days</button>
                </template>
                <template #action-buttons>
                  <button type="button" class="btn btn-outline-secondary me-2  px-1 py-0"
                    @click="closeLogPicker">Cancel</button>
                  <button type="button" class="btn btn-sm btn-primary px-1 py-0" @click="selectLogDate">Confirm</button>
                </template>
              </VueDatePicker>

              <select v-model="logs_action_filter" class="form-select me-2" id="filter-action">
                <option value="">All actions</option>
                <option :value="action?.name" v-for="action,i in actionWorkflowOption" :key="i">{{action?.label}}
                </option>
              </select>

              <select class="form-select me-2" v-model="logs_status_filter" id="filter-event">
                <option value="">All Status</option>
                <option value="finished">Finished</option>
                <option value="inprogress">In Progress</option>
                <option value="failed">Failed</option>
              </select>

              <select v-model="logs_contact_filter" class="form-select me-2" id="filter-event">
                <option value="">All Contact</option>
                <option :key="i" :value="contact" v-for="contact, i in contactFilters">{{contact}}</option>
              </select>
            </template>
            <button class="btn btn-default text-sm text-normal btn-refresh" @click="getlogsHistory()"
              :disabled="isLogsLoading">
              <i class="fa-solid fa-arrows-rotate" :class="{'fa-spin':isLogsLoading}"></i>
            </button>
            <button v-if="enrollmentHistory.length > 0 && enrollmentHistory.length < totalEvents"
              :disabled="isLogsLoading" class="btn btn-default  text-normal btn-refresh ms-2 w-100"
              style="max-width: 150px;" @click="eventsPage += 1; getlogsHistory()">
              <template v-if="isLogsLoading">
                Loading... <i class="fa fa-circle-o-notch fa-spin"></i>
              </template>
              <template v-else>
                Load {{eventsPerPage}} More
              </template>
            </button>

          </div>
        </div>


        <div class="table-responsive mt-4 execution-logs-table p-0 py-1 pb-4 shadow-executionlog"
          v-show="activeTab  == 'executionhistory'">
          <table class="table table-hover">
            <thead class="table-white">
              <tr class="text-sm text-normal">
                <th scope="col">
                  <div class="text-sm p-3 py-1 fw-normal">Contact</div>
                </th>
                <th scope="col">
                  <div class="text-sm p-3 py-1 fw-normal">Date Enrolled</div>
                </th>
                <th scope="col">
                  <div class="text-sm p-3 py-1 fw-normal">Current Action</div>
                </th>
                <th scope="col">
                  <div class="text-sm p-3 py-1 fw-normal">Current Status</div>
                </th>
                <th scope="col">
                  <div class="text-sm p-3 py-1 fw-normal"></div>
                </th>
              </tr>
            </thead>

            <tbody class="position-relative">
              <div v-if="eventsPage > 1 && isLogsLoading"
                class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
                style="background-color:rgba(0,0,0,0.2); ">
                <span style="font-size: 20px; background: transparent;">
                  <span class="me-1">Loading...</span> <i class="fa fa-circle-o-notch fa-spin"></i>
                </span>
              </div>
              <tr class="text-sm text-normal" :key="i" v-for="event, i in paginatedHistory">
                <td>
                  <div @click="openConversationinNewTab(event)" class="p-3 py-1 cursor-pointer" data-bs-toggle="tooltip"
                    data-bs-placement="top" data-bs-trigger="hover" :title="event.sourceEmail">
                    <button v-if="event?.sourceEmail" class="btn user-image" :style="{ 
                        backgroundColor: getEmailColor(event.sourceEmail),
                        color: isColorLight(getEmailColor(event.sourceEmail)) ? 'black' : 'white'
                        }">
                      {{getUserInitials(event.sourceEmail)}}
                    </button>
                    {{ event.sourceEmail }}
                  </div>
                </td>
                <td>
                  <div class="p-3 py-1">{{ getTimeStamp(event?.startTimestamp, 'MMMM Do YYYY, h:mm:ss a', true) }}</div>
                </td>
                <td>
                  <div class="p-3 py-1 text-capitalize">{{ getLastAction(event) }}</div>
                </td>
                <td>
                  <div class="p-3 py-1 text-capitalize">
                    <button :class="executionStatus(event?.currentState)"
                      class="rounded-pill text-col-navlink text-xs btn-sm py-1 px-3 text-bold mb-0 no-shadow me-1 text-col-status border-0 cursor-auto">
                      {{ event?.currentState == 0  ? 'Finished' :  event?.currentState > 0 ? 'In progress' : 'Failed'}}
                    </button>
                  </div>
                </td>
                <td>
                  <div class="p-3 py-1">
                    <i data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                      @click="viewExecutionHistory(event)" title="View Execution History"
                      class="fa fa-history cursor-pointer" aria-hidden="true"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="isLogsLoading && eventsPage == 1 || (getHistory.length == 0)"
            class="d-flex justify-content-center align-items-center flex-column py-5 w-100 mx-auto">
            <i class="fa-2x mb-2 text-primary"
              :class="isLogsLoading ? 'fa fa-circle-o-notch fa-spin' : 'fas fa-search '"></i>
            <div class="text-lg p-0">{{isLogsLoading ? 'Loading History... ' : 'No Enrollments Found'}}</div>
            <div class="text-sm p-0">Enrollment History is available up to the last 30 days.</div>
          </div>

          <Pagination :currentPageReport="true" :total-records="getHistory.length" :page-size="history_per_page"
            :current-page="history_page" :max-links="5" :per-page-options="[5,10,20, 50, 100]"
            @update:pagination="changeHistoryPage({page:$event.page, rows:$event.per_page})">
          </Pagination>
        </div>

        <div class="table-responsive mt-4 execution-logs-table p-0 py-1 pb-4 shadow-executionlog"
          v-show="activeTab  == 'executionlogs'">
          <div class="px-2" v-if="execution_history_id || logs_conversation_filter">
            <span class="text-xs cursor-pointer" @click="removeLogsFilter()">logs &nbsp;/&nbsp;</span>
            <span class="text-xs cursor-pointer">{{execution_history_email }} <span
                v-if="execution_history_id">&nbsp;/&nbsp;</span> </span>
            <span class="text-xs cursor-pointer" v-if="execution_history_id">{{execution_history_id }}</span>
          </div>
          <table class="table table-hover">
            <thead class="table-white">
              <tr class="text-sm text-normal">
                <th scope="col">
                  <div class="text-sm p-3 py-1 fw-normal">Contact</div>
                </th>
                <th scope="col">
                  <div class="text-sm p-3 py-1 fw-normal">Action</div>
                </th>
                <th scope="col">
                  <div class="text-sm p-3 py-1 fw-normal">Status</div>
                </th>
                <th scope="col">
                  <div class="text-sm p-3 py-1 fw-normal">Executed On</div>
                </th>
                <th style="min-width: 120px;"></th>
              </tr>
            </thead>

            <tbody>
              <tr class="text-sm text-normal" :key="i" v-for="event, i in paginatedLogs">
                <td>
                  <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                    @click="openConversationinNewTab(event); removeToolTip()" :title="event.sourceEmail"
                    class="p-3 py-1 d-flex align-items-center cursor-pointer">
                    <button class="btn btn-primary label-btn d-flex align-items-center justify-content-center me-2"
                      style="width: 35px; height: 35px; border">#</button>
                    <span>{{ event?.threadId }}</span>
                  </span>
                </td>
                <td>
                  <div class="p-3 py-1 text-capitalize">
                    <svg v-if="event?.metaData?.actionId == 'modalai'" class="action-icon my-2" viewBox="0 0 513 421"
                      fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path id="SymbolLogo"
                        d="M486.367 5.66518C470.401 -2.0562 451.334 -0.0486403 437.383 10.7613L256.331 151.754L74.9685 10.298C60.8626 -0.357495 41.9513 -2.0562 26.1402 5.66518C10.4841 13.541 0.408447 29.6015 0.408447 47.2062V373.821C0.408447 391.58 10.4841 407.64 26.6052 415.516C33.1157 418.605 40.0912 420.149 46.9116 420.149C57.1423 420.149 67.218 416.752 75.5886 410.266L220.058 301.703C208.123 291.511 168.905 260.471 141.623 238.697L98.0651 274.06V149.746L175.88 210.9L254.781 275.605L255.091 275.296L437.228 410.266C451.179 421.23 470.246 423.238 486.212 415.516C502.178 407.795 512.408 391.58 512.408 373.821V47.2062C512.408 29.447 502.333 13.3866 486.367 5.66518ZM414.752 276.995L336.781 213.37L414.752 149.901V276.995Z"
                        fill="currentColor" />
                    </svg>

                    <img class="action-icon" src="@/assets/img/chatgpt.svg" v-else-if="findWorkflowByName(event?.metaData?.actionId)?.name == 'chatgpt'" />
                    <i v-else class="action-icon" :class="event?.metaData?.branchCondition ? 'fa-solid fa-sitemap' : findWorkflowByName(event?.metaData?.actionId)?.icon"></i>
                    {{ getActionLabel(event, true)  }}
                  </div>
                </td>
                <td>
                  <div class="p-3 py-1 text-capitalize">
                    <button :class="executionStatus(event?.state)"
                      class="rounded-pill text-col-navlink text-xs btn-sm py-1 px-3 text-bold mb-0 no-shadow me-1 text-col-status border-0 cursor-auto">
                      {{ event?.state == 0 ? 'Finished' :  event?.state > 0 ? 'In progress' : 'Failed'}}
                    </button>
                  </div>
                </td>
                <td>
                  <div class="p-3 py-1">
                    {{ getTimeStamp(event?.metaData?.timestamp, 'MMMM Do YYYY, h:mm:ss a', false) }}
                  </div>
                </td>
                <td>
                  <span @click="selectedLog = event; removeToolTip()" class="view-details-btn cursor-pointer me-4">
                    View Details
                  </span>
                  <i :class="execution_history_id == event?.eventRunId ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'"
                    data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                    @click="viewExecutionHistory(event)" title="View Execution History" class="fa fa-history me-4"
                    aria-hidden="true"></i>
                  <i :class="logs_conversation_filter == event?.threadId.toString() ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'"
                    data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                    @click="viewContactHistory(event)" title="View Contact History" class='fas fa-user-check  me-4'></i>
                  <i v-if="event?.metaData?.actionUid" @click="goToAction(event?.metaData?.actionUid)"
                    data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Go To Action"
                    class="fa-regular fa-circle-arrow-right cursor-pointer"></i>
                  <i v-else data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                    title="Action not openable or deleted/modified from workflow"
                    class="fa-regular fa-circle-arrow-right opacity-50 cursor-not-allowed"></i>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="(isLogsLoading && eventsPage == 1 )|| (getLogs.length == 0)"
            class="d-flex justify-content-center align-items-center flex-column py-5 w-100 mx-auto">
            <i class="fa-2x mb-2 text-primary"
              :class="isLogsLoading ? 'fa fa-circle-o-notch fa-spin' : 'fas fa-search '"></i>
            <div class="text-lg p-0">{{isLogsLoading ? 'Loading Logs... ' : 'No Logs Found'}}</div>
            <div class="text-sm p-0">Logs are available up to the last 30 days.</div>
          </div>

          <Pagination :total-records="getLogs.length" :page-size="logs_per_page" :current-page="logs_page"
            :max-links="5" :per-page-options="[5,10,20, 50, 100]"
            @update:pagination="changeLogsPage({page:$event.page, rows:$event.per_page})">
          </Pagination>
        </div>

      </div>
    </div>

    <!---->
  </div>
</template>
<script>
import moment from 'moment';
import Api from '../Api';
import setTooltip from '@/assets/js/tooltip.js';
import Pagination from '@/components/Pagination.vue';
// import Api from '../Api';

  export default {
    name: 'WorkflowExecutionLogs',
    props: ['run_for_child', 'childAgenciesList', 'isChildAgenciesLoading', 'child_agencies', 'isErrors', 'activeTab', 'nodes', 'queryParamsObject', 'workflowJson'],
    emits: ['change_run_for_child', 'child_agencies', 'isErrors', 'activeTab'],
    components:{
      Pagination
    },
    data() {
      return {
        agencies: [],
        no_logs_found: true,
        enrollmentHistory:[],
        logsHistory:[],
        contactFilters:[],
        isLogsLoading:false,
        isHistoryLoading:false,
        showDetails:false,
        historyDurationFilter:'last30days',
        logsDurationFilter:'last30days',
        logs_per_page:10,
        logs_page:1,
        logs_status_filter:'',
        logs_action_filter:'',
        logs_contact_filter:'',
        history_contact_filter:'',
        history_event_filter:'',

        maxDate:'',
        minDate:'',

        historyDurationFilterDate:[],
        historyDurationFilterDateLocal:[],
        logsDurationFilterDate:[],
        logsDurationFilterDateLocal:[],

        history_per_page:10,
        history_page:1,
        eventsPage:1,
        eventsPerPage:500,
        totalEvents:0,
        selectedLog:null,
        logs_conversation_filter:this.$route?.query?.logs_conversation_filter || '',
        execution_history_id:this.$route?.query?.execution_history_id || '',
        execution_history_email:this.$route?.query?.execution_history_email || '',
        rerenderLogsPagination:true,
        rerenderHistoryPagination:true,
        actions: {
          initials:[
            {label:'Automation Triggered', name :'workflowTriggered', icon:'fa fa-plus-circle'},
            {label:'Automation Completed', name:'workflowCompleted',  icon:'fa fa-check'  },
            {label:'Automation Started', name:'workflowStarted',  icon:'fa fa-plus-circle'  }
          ],
          chat: [{ label: 'When Chat Ends', name: 'onChatEnded', type: 'input', filters: [], showAddFilterBtn: true, hasFilters: true, icon: 'fas fa-comment-slash', isTrigger:true }],
          message: [
            {
              label: 'New Message',
              name: 'onNewMessage',
              type: 'input',
              filters: [],
              showAddFilterBtn: true,
              hasFilters: true,
              icon: 'fa fa-messages',
              isTrigger:true
            },
          ],

          CSAT: [{ label: 'CSAT Submitted', name: 'conversationRated', type: 'input', filters: [], showAddFilterBtn: false, hasFilters: true, icon: 'fa fa-star-half-o', isTrigger:true  }],
          ticket: [{ label: 'Ticket Created', name: 'ticketCreated', type: 'input', filters: [], showAddFilterBtn: true, hasFilters: true, icon: 'fa fa-ticket', isTrigger:true }],
       
          ai: [
            { label: 'GPT powered by OpenAI', name: 'chatgpt', type: 'input', filters: [], hasFilters: false, model: 'gpt-4o', prompt: '', temperature: '', max_tokens: '', top_p: '' },
            { label: 'Anthropic (Claude)', name: 'claude', type: 'input', filters: [], hasFilters: false, model: 'claude-3-haiku-20240307', prompt: '', temperature: '', max_tokens: '', top_p: '', top_k:'', icon:'fa-solid fa-microchip-ai' },
            { label: 'Google AI Studio (Gemini)', name: 'gemini', type: 'input', filters: [], hasFilters: false, model: 'gemini-1.5-flash', prompt: '', stopSequences:[], maxOutputTokens:'', temperature:'', icon:'fa-brands fa-google' },
            { 
              label: 'Meta (Llama 3.0)', 
              name: 'llama', type: 'input', 
              filters: [],
              hasFilters: false, 
              model: 'llama3.1-70b', 
              prompt: '', 
              temperature: '', 
              max_tokens: '', 
              top_p: '',
              icon:'fa-brands fa-meta' 
            },
            { 
              label: 'Modal Ai', 
              name: 'modalai', 
              type: 'input', 
              filters: [],
              hasFilters: false, 
              prompt: '', 
              icon:'fa-brands fa-meta' 
            },
          ],
          assignment: [
            {
              label: 'Assign To',
              name: 'assignTicket',
              type: 'input',
              filters: [],
              assignedUsers: [],
              roundRobin: false,
              showAddFilterBtn: false,
              hasFilters: false,
              icon: 'fa fa-solid fa-user-check',
            },
          ],
          internal_tools: [
            {
              label: 'If / Else',
              name: 'condition',
              type: 'input',
              filters: [],
              showAddFilterBtn: false,
              hasFilters: false,
              branches: [
                {
                  branch_name: '',
                  id: `branch-${moment().valueOf()}`,
                  condition: {
                    type: 'AND',
                    conditions: [
                      {
                        type: '',
                        operator: '',
                        value: '',
                      },
                    ],
                  },
                },
              ],
              icon: 'fas fa-brackets-curly',
            },
            {label: 'Wait Time', name: 'delayAction', type: 'input', filters: [], showAddFilterBtn: false, hasFilters: false, time:0, icon: 'fa fa-history'}
          ],
          messaging: [
            { label: 'Auto Reply', name: 'autoReply', subject: '', message: '', type: 'input', replyType: '', filters: [], showAddFilterBtn: false, hasFilters: false, icon: 'fa-solid fa-reply' },
            { label: 'Forward an Email', name: 'forwardEmail', type: 'input', email: '', filters: [], showAddFilterBtn: false, hasFilters: false, icon: 'fa-solid fa-share' },
            { label: 'Redirect Email', name: 'redirectEmail', type: 'input', email: '', filters: [], showAddFilterBtn: false, hasFilters: false, icon: 'fa-solid fa-share' },
          ],
          note: [{ label: 'Add a Note', name: 'createNote', note: '', type: 'input', filters: [], assignedUsers: [], showAddFilterBtn: false, hasFilters: false, icon: 'fa fa-solid fa-notes' }],
          tags: [
            { label: 'Add Tags', name: 'addTag', type: 'input', filters: [], selectedTags: [], showAddFilterBtn: false, hasFilters: false, icon: 'fa fa-tag' },
            { label: 'Remove Tags', name: 'removeTag', type: 'input', filters: [], selectedTags: [], showAddFilterBtn: false, hasFilters: false, icon: 'fa-regular fa-tag' },
            { label: 'Remove All Tags', name: 'removeAllTags', type: 'input', filters: [], showAddFilterBtn: false, hasFilters: false, icon: 'fa-regular fa-tags' },
          ],
          ticket_status: [{ label: 'Ticket Status Change', name: 'updateTicketStatus', type: 'input', ticket_status_id: '', filters: [], hasFilters: false, icon: 'fa fa-tag' }],
          webhook: [
            { label: 'Webhook', name: 'webhook', type: 'input', method: 'post', api_url: '', customData: [], headers: [], filters: [], hasFilters: false, icon: 'fa fa-wifi' },
            { label: 'Send to AI Response', name: 'fetchConversation', type: 'input', api_url: '', headers: [], sendToModalSupport: false, filters: [], hasFilters: true, icon: 'fas fa-comment-alt' },
          ],
        },
        dataUrl:''
      };
    },
    mounted() {
      this.agencies = this.child_agencies;
      if(this.activeTab == 'executionhistory' || this.activeTab == 'executionlogs') {
        this.getlogsHistory()
      }
       // Set maxDate to the current date
      this.maxDate = new Date();

      // Clone maxDate before modifying for minDate
      let minDate = new Date(this.maxDate);
      this.minDate = new Date(minDate.setDate(minDate.getDate() - 30));
      this.historyDurationFilterDate=[this.minDate, this.maxDate]
      this.historyDurationFilterDateLocal=[this.minDate, this.maxDate]
      this.logsDurationFilterDate=[this.minDate, this.maxDate]
      this.logsDurationFilterDateLocal=[this.minDate, this.maxDate]
    },
    computed:{
      paginatedLogs() {
        const start = (this.logs_page - 1) * this.logs_per_page;
        const end = start + this.logs_per_page;
        return this.getLogs.slice(start, end);
      },
      paginatedHistory() {
        const start = (this.history_page - 1) * this.history_per_page;
        const end = start + this.history_per_page;
        return this.getHistory.slice(start, end);
      },
      getLogs() {
        let logs = [...this.logsHistory]; // Copying logsHistory

        if (this.logsDurationFilterDate.length > 1) {
          let startDate = moment(this.logsDurationFilterDate[0]).startOf('day'); // Ensure start of the day
          let endDate = moment(this.logsDurationFilterDate[1]).endOf('day'); // Ensure end of the day

          // Filter the history using startDate and endDate
          logs = logs.filter((el) => {
            let timestamp = moment.unix(el?.timestamp);
            return timestamp.isSameOrAfter(startDate) && timestamp.isSameOrBefore(endDate);
          });

        }

        if(this.logs_action_filter) {
          logs = logs.filter((el) => el?.metaData?.actionId == this.logs_action_filter)
        }
        
        if(this.logs_status_filter) {
          if(this.logs_status_filter == 'finished') {
            logs = logs.filter((el) => el.state == 0)
          } else if(this.logs_status_filter == 'inprogress') {
            logs = logs.filter((el) => el.state > 0)
          } else {
            logs = logs.filter((el) => el.state < 0)
          }
        }
        if(this.execution_history_id) {
          logs = logs.filter((el) => el.eventRunId == this.execution_history_id)
        }

        if(this.logs_contact_filter) {
          logs = logs.filter((el) => el.sourceEmail == this.logs_contact_filter)
        }

        if(this.logs_conversation_filter) {
          logs = logs.filter((el) => el.threadId == this.logs_conversation_filter)
        }
        return logs.sort((a,b) => b.metaData.timestamp - a.metaData.timestamp);
        
      },
      getHistory() {
        let history = [...this.enrollmentHistory]; // Copying logsHistory

        if (this.historyDurationFilterDate.length > 1) {
          let startDate = moment(this.historyDurationFilterDate[0]).startOf('day'); // Ensure start of the day
          let endDate = moment(this.historyDurationFilterDate[1]).endOf('day'); // Ensure end of the day

          // Filter the history using startDate and endDate
          history = history.filter((el) => {
            let startTimestamp = moment.unix(el?.startTimestamp);
            return startTimestamp.isSameOrAfter(startDate) && startTimestamp.isSameOrBefore(endDate);
          });

        }
        if(this.history_event_filter) {
          history = history.filter((el) => el.eventTrigger == this.history_event_filter)
        }
        if(this.history_contact_filter) {
          history = history.filter((el) => el.sourceEmail == this.history_contact_filter)
        }
        return history.sort((a,b) => b.startTimestamp - a.startTimestamp);
        
      },
      triggerWorkflowOption() {
         // Get all workflow categories
        const categories = Object.values(this.actions);
        let workflows = []
        
        // Flatten the categories and search for the object with the matching `name`
        for (const category of categories) {
          const data = category.filter(workflow => workflow?.isTrigger);
          workflows = [...workflows, ...data]
        }

        return workflows; // Return null if not found
      },
      actionWorkflowOption() {
         // Get all workflow categories
        const categories = Object.values(this.actions);
        let workflows = []
        
        // Flatten the categories and search for the object with the matching `name`
        for (const category of categories) {
          const data = category.filter(workflow => !workflow?.isTrigger);
          workflows = [...workflows, ...data]
        }

        return workflows; // Return null if not found
      }
    },
    updated() {
      this.initializeTooltips();
    },
    methods:{
      getOriginalCondtions(id) {
        let condition = null
        const findInBranches = (id, el) => {
            if(el?.branches) {
              el.branches.forEach((ele) => {
                findInBranches(id, ele)
            })
            if(el.id == id) {
              console.log('el ', el)
              condition = el
            }
          }
        }
        this.workflowJson.branches.forEach((el) => {
          findInBranches(id, el)
        })
        return condition
      },
      getActionLabel(event, slice = null) {
        let label = ''
        if( event?.metaData?.branchCondition) {
          if(event?.metaData?.branchName?.length > 25 && slice) {
            label = event?.metaData?.branchName.slice(0, 25) + '...' 
          } else {
            label = event?.metaData?.branchName 
          }

        } else {
          if(this.findWorkflowByName(event?.metaData?.actionId)?.label) {
            if(this.findWorkflowByName(event?.metaData?.actionId)?.label.length > 25 && slice) {
              label = this.findWorkflowByName(event?.metaData?.actionId)?.label.slice(0, 25)
            } else {
              label = this.findWorkflowByName(event?.metaData?.actionId)?.label 
            } 
          }
        } 
        return label
      }, 
      removeLogsFilter() {
        let query = {
          activetab:'executionlogs',
          execution_history_id:  '', 
          execution_history_email:'',
          logs_conversation_filter:''
        }
        if(this.$route?.query?.redirecturl) {
          query = {
            ...query,
            redirecturl:decodeURIComponent(this.$route?.query?.redirecturl), 
          }
        }
        window.parent.postMessage(
          { type: 'query', query:query}, 
          '*'
        );
      },
      viewExecutionHistory(event) {
        if(event?.eventRunId && this.execution_history_id !== event?.eventRunId) {
          this.removeToolTip()
          let query = {
            activetab:'executionlogs',
            execution_history_id:  event?.eventRunId, 
            execution_history_email:encodeURIComponent(event?.sourceEmail)
          }
          if(this.$route?.query?.redirecturl) {
            query = {
              ...query,
              redirecturl:decodeURIComponent(this.$route?.query?.redirecturl), 
            }
          }
          console.log('query ', query);
          window.parent.postMessage(
            { type: 'query', query:query}, 
            '*'
          );
        }
      },
      viewContactHistory(event) {
        if(event?.threadId && this.logs_conversation_filter !== event?.threadId.toString())  {
          this.removeToolTip();
          let query = {
            activetab:'executionlogs',
            logs_conversation_filter:  event?.threadId, 
            execution_history_email:encodeURIComponent(event?.sourceEmail)
          }
          if(this.$route?.query?.redirecturl) {
            query = {
              ...query,
              redirecturl:decodeURIComponent(this.$route?.query?.redirecturl), 
            }
          }
          console.log('query ', query);
          window.parent.postMessage(
            { type: 'query', query:query}, 
            '*'
          );
        }
      },
      getLastAction(event) {
        if(event.executionHistory.length) {
          let logs = event.executionHistory.sort((a,b) => b.metaData.timestamp - a.metaData.timestamp )
          return this.findWorkflowByName(logs[0]?.metaData?.actionId)?.label
        }
      },
      goToAction(actionUid) {
        this.$store.state.goToAction = actionUid;
        this.$emit('activeTab', 'builder')
        this.removeToolTip()
      },
      setFilterDate(type, filter) {
          let startDate, endDate;

          // Set the end date to the current date
          endDate = moment().endOf('day');

          // Determine the start date based on the filter using if-else conditions
          if (filter === 'thisweek') {
            startDate = moment().startOf('week');
          } else if (filter === 'lastweek') {
            startDate = moment().subtract(1, 'week').startOf('week');
            endDate = moment().subtract(1, 'week').endOf('week');
          } else if (filter === 'last7days') {
            startDate = moment().subtract(7, 'days').startOf('day');
          } else if (filter === 'thismonth') {
            startDate = moment().startOf('month');
          } else if (filter === 'last15days') {
            startDate = moment().subtract(15, 'days').startOf('day');
          } else if (filter === 'last30days') {
            startDate = moment().subtract(30, 'days').startOf('day');
          } else {
            // Default to last 30 days if no matching filter is found
            startDate = moment().subtract(30, 'days').startOf('day');
          }

          // Update the corresponding filter date range based on the type
          if (type === 'historyDurationFilterDate') {
            this.historyDurationFilterDateLocal = [startDate.toDate(), endDate.toDate()];
            this.$refs.dp.closeMenu()
          } else if (type === 'logsDurationFilterDate') {
            this.logsDurationFilterDateLocal = [startDate.toDate(), endDate.toDate()];
            this.closeLogPicker()
          }

          console.log(`${type} updated: `, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
      },
      selectLogDate() {
        this.$refs.logdp.selectDate()
      },
      closeLogPicker() {
        this.$refs.logdp.closeMenu()
      },
      closePicker() {
        this.$refs.dp.closeMenu()
      },

      checkHistoryCalendarChange(event) {
        console.log('event ', event)
      },

      selectDate() {
        this.$refs.dp.selectDate()
      },
      getEmailColor(email) {
        const hash = this.hashString(email);
        return this.intToRGB(hash);
      },

      isColorLight(color) {
        // Remove '#' if present and parse RGB values
        const r = parseInt(color.substr(1, 2), 16);
        const g = parseInt(color.substr(3, 2), 16);
        const b = parseInt(color.substr(5, 2), 16);

        // Calculate brightness (standard luminance formula)
        const brightness = (0.299 * r + 0.587 * g + 0.114 * b);
        return brightness > 186; // Threshold for light colors
      },


      // Convert the integer hash to an RGB hex color
      
      intToRGB(hash) {
        let color = '#';
        for (let i = 0; i < 3; i++) {
          const value = (hash >> (i * 8)) & 0xFF;
          color += ('00' + value.toString(16)).slice(-2);
        }
        return color;
      },

      // Hash function to convert email to an integer
      hashString(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
      },

    

      // Function to get initials from the email
      getUserInitials(email) {
        const initials = email.split('@')[0].split('.').map(part => part[0].toUpperCase()).join('');
        return initials;
      },
      openConversationinNewTab(event) {
        this.removeToolTip()
        let url = `https://dev.modalsupport.com/admin/inbox?tab=archives&conversation_ref=${event.threadId}&activeSection=automation`
        window.open(url, '_blank')
      },
      removeToolTip() {
        const tooltip = document.querySelector('.tooltip');
        if (tooltip) {
          tooltip.remove();
        }
      },
      initializeTooltips() {
        this.$nextTick(() => {
          setTooltip(this.$store.state.bootstrap); 
        });
      },
      findWorkflowByName(name) {
        // Get all workflow categories
        const categories = Object.values(this.actions);
        
        // Flatten the categories and search for the object with the matching `name`
        for (const category of categories) {
          const found = category.find(workflow => workflow.name === name || workflow.label === name);
          if (found) return found;
        }

        return null; // Return null if not found
      },
      changeLogsPage(event) {
        this.logs_page = event.page;
        this.logs_per_page = event.rows
      },
      changeHistoryPage(event) {
        this.history_page = event.page;
        this.history_per_page = event.rows
      },
      getTimeStamp(time, format, isUnix) {
        if (time) {
          if(isUnix) {
            return moment.unix(time).format(format);
          } else {
            return moment(time).format(format);
          }
        } 
      },
      executionStatus(status) {
        if(status == 0) {
          return 'badge-success text-success'
        } 
        else if(status > 0) {
          return 'badge-orange text-black badge-blue'
        } else {
          return 'badge-warning text-danger'
        }
      }, 
      formatLabel(str) {
        if (str) {
          return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, function (str) {
            return str;
          });
        }
      },
      getlogsHistory() {
        this.isLogsLoading = true;
        this.contactFilters = []
        this.logsHistory = []
        if(this.eventsPage == 1) {
          this.enrollmentHistory = []
        }
        let payload =   {
          page:this.eventsPage,
          pageSize:this.eventsPerPage
        }

        console.log('this.activeTab ', this.activeTab)
        if(this.activeTab == 'executionlogs' && this.$route?.query?.fetchlog) {
          payload = {
            ...payload,
            event_run_id:this.$route?.query?.execution_history_id
          }
        } else {
          payload = {
            ...payload,
            workflow_ref:this.$route.query.automation_ref,
          }
        }

        console.log('payload ', payload)
        
        Api.getLogEvents(payload).then((res) => {
          // if(res?.data?.totalCount) {
            this.totalEvents = res?.data?.totalCount || 0
          // }
          if(res?.data?.events) {
            this.enrollmentHistory = [...this.enrollmentHistory, ...res.data.events]
            this.enrollmentHistory.forEach((el) => {
              if(el.sourceEmail && !this.contactFilters.includes(el.sourceEmail)) {
                this.contactFilters.push(el.sourceEmail)
              }
              if(el?.executionHistory?.length) {
                el?.executionHistory.forEach((log) => {
                  this.logsHistory.push({...log, sourceEmail:el.sourceEmail, eventRunId:el.eventRunId, threadId:el.threadId})
                })
              }
            })
          }
        }).catch((err) => {
          console.log('error while getting logs history ', err)
        }).finally(() => {
          this.isLogsLoading = false
          setTimeout(() => {
              setTooltip(this.$store.state.bootstrap);
            }, 100);
        })
      }
    },
    watch: {
      queryParamsObject: {
        handler() {
          console.log('this.queryParamsObject', this.queryParamsObject)
          if(this.queryParamsObject?.execution_history_email) {
            this.execution_history_email = decodeURIComponent(this.queryParamsObject?.execution_history_email)
          } else {
            this.execution_history_email = ''
          }
          if(this.queryParamsObject?.execution_history_id) {
            this.execution_history_id = this.queryParamsObject?.execution_history_id
          } else {
            this.execution_history_id = ''
          }
          if(this.queryParamsObject?.logs_conversation_filter) {
            this.logs_conversation_filter = this.queryParamsObject?.logs_conversation_filter
          } else {
            this.logs_conversation_filter = ''
          }

          if(this.queryParamsObject?.activetab) {
            this.$emit("activeTab", this.queryParamsObject?.activetab)
          }
        }
      }, 
      selectedLog:{
        handler() {
          console.log('this.selectedLog :- ', this.selectedLog)

          
        }
      },
      logsDurationFilterDateLocal:{
        handler() {
          if(!this.logsDurationFilterDateLocal[1]) {
            this.$store.state.showToast = {
              icon: 'fa fa-exclamation-triangle',
              title: `Attention`,
              description: `Please select end date`,
              time: moment().valueOf(),
            };
            this.$refs.logdp.openMenu()
          } else {
            this.logsDurationFilterDate = this.logsDurationFilterDateLocal
          }
        }
      },
      logsDurationFilterDate: {
        handler() {
          this.logs_page = 1 
          this.rerenderLogsPagination = !this.rerenderLogsPagination
        }
      },
      logs_status_filter: {
        handler() {
          this.logs_page = 1;
          this.rerenderLogsPagination = !this.rerenderLogsPagination
        }
      },
      logs_action_filter: {
        handler() {
          this.logs_page = 1;
          this.rerenderLogsPagination = !this.rerenderLogsPagination
        }
      },
      logs_conversation_filter: {
        handler() {
          this.logs_page = 1 
          this.rerenderLogsPagination = !this.rerenderLogsPagination
        }
      },
      historyDurationFilterDateLocal: {
        handler() {
          if(!this.historyDurationFilterDateLocal[1]) {
            this.$store.state.showToast = {
              icon: 'fa fa-exclamation-triangle',
              title: `Attention`,
              description: `Please select end date`,
              time: moment().valueOf(),
            };
            this.$refs.dp.openMenu()
          } else {
            this.historyDurationFilterDate = this.historyDurationFilterDateLocal
          }
        }
      },
      historyDurationFilterDate: {
        handler() {
          this.history_page = 1 
          this.rerenderHistoryPagination = !this.rerenderHistoryPagination
        }
      },
      execution_history_id: {
        handler() {
          this.logs_page = 1 
          this.rerenderLogsPagination = !this.rerenderLogsPagination
        }
      },
      history_event_filter: {
        handler() {
          this.history_page = 1 
          this.rerenderHistoryPagination = !this.rerenderHistoryPagination
        }
      },
      activeTab : {
        handler() {
          if(this.activeTab == 'executionhistory') {
            this.getlogsHistory()
          }
          if(this.activeTab == 'executionlogs' && !this.$store.state.isExecutionLogsFetched) {
            this.$store.state.isExecutionLogsFetched = true
            this.getlogsHistory()

          }
          this.historyDurationFilterDateLocal = this.historyDurationFilterDate;
          this.logsDurationFilterDateLocal = this.logsDurationFilterDate;
        }
      },
      agencies: {
        deep: true,
        handler() {
          this.$emit('child_agencies', this.agencies);
        },
      },
    },
  };
</script>
<style scoped>
  .execution-logs-table {
    font-weight: 400;
    border: 1px solid #e8e8e8 !important;
    border-radius: 10px !important;
  }
  .execution-logs-table tr {
    border-color: #f0f0f0 !important;
  }
  .execution-logs-table tr:hover {
    background-color: #f0f0f0 !important;
  }

  .shadow-executionlog {
    border: 1px solid #eaecf0;
    box-shadow: 0 1px 3px #1018281a, 0 1px 2px #1018280f;
  }
  .btn-refresh {
    border: 1px solid #e2e2e2 !important;
    border-radius: 7px !;
  }
  .table-hover > tbody > tr:hover > * {
    --bs-table-bg-state: #f6f6f6 !important;
  }
 .badge-orange {
  color: orange !important;
  background-color: rgb(253, 242, 222) !important;
}
.badge-orange:hover {
  color: orange !important;
  background-color: rgb(255, 231, 187) !important;
}

.badge-success {
  background-color: rgba(215, 239, 220, .6) !important;
}
 .badge-success:hover {
  background-color: rgba(215, 239, 220, .6) !important;
}
.badge-warning {
  color: red !important;
  background-color: rgb(255, 220, 220) !important;
}
.badge-warning:hover {
  color: red !important;
  background-color: rgb(255, 200, 200) !important;
}
.history-label {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  letter-spacing: .025em;
  font-weight: 500;
  text-transform: capitalize;
}

::v-deep .p-paginator-page.p-paginator-page-selected {
  background: var(--base-white) !important;
  color: var(--primary-color) !important;
  border-radius: 4px !important;
  border: 1px solid var(--primary-color) !important;
  width: 32px !important;
  height: 32px !important;
}

::v-deep .p-paginator-page:not(.p-disabled):not(.p-paginator-page-selected):hover,
::v-deep .p-paginator-first:not(.p-disabled):hover,
::v-deep .p-paginator-prev:not(.p-disabled):hover,
::v-deep .p-paginator-next:not(.p-disabled):hover,
::v-deep .p-paginator-last:not(.p-disabled):hover {
  background: var(--base-white) !important;
  color: var(--primary-color) !important;
  border-radius: 4px !important;
  border: 1px solid var(--gray-100) !important;
  width: 32px !important;
  height: 32px !important;
}

::v-deep .p-select.p-disabled {
  opacity: 1;
  background: var(--base-white) !important;
}

::v-deep .p-paginator {
  justify-content: end !important;
}

.bg-log-background {
  --tw-bg-opacity: 1;
    background-color: rgba(12, 45, 63, var(--tw-bg-opacity));
}
.text-log {
    --tw-text-opacity: 1;
    color: rgba(158, 171, 178, var(--tw-text-opacity));
}
.cursor-auto {
  cursor: auto!important;
}
.action-icon {
  font-size: 12px;
  margin-right: 5px;;
  height: 30px;
  width: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: auto;
  --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.user-image {
  font-size: 12px;
  margin-right: 5px;;
  height: 30px;
  width: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%!important;
  padding: 0!important;
}
.label-btn {
  border-radius: 50%!important;
  padding: 0!important;
}
::v-deep .dp__input {
  line-height: 1.9!important;
}

::v-deep .dp__action_extra {
    text-align: left !important;
    padding: 8px!important;
}
::v-deep .dp__selection_preview{
  display: none!important;
}
::v-deep .dp__action_buttons {
  margin-top: -50px;
}
.execution_history_email_label {
  font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
}
.execution_history_email_desc {
  --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  font-size: 1rem;
    line-height: 1.5rem;
}
.view-details-btn {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
}
.execution-logs-table table td {
  vertical-align: middle;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.fs-14 {
  font-size: 14px!important;
}
</style>
