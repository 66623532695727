<template>
  <div class="form-scroll" ref="automationActionForm" style="height: calc(100% - 10px); overflow-y: scroll">
    <div v-if="showSubPanel">
      <div class="h5 mb-0 py-3 d-flex border-bottom p-3">
        <div class="col-6">{{ selectedData.label }}</div>
        <div class="text-indigo-200 col-6 text-end" @click="
            $emit('isOpen', !isOpen), $emit('showSubPanel', showSubPanel), $emit('resetPanel', '');
            (cursorPosition = null), (selectedCursorField = '');
          ">
          <svg class="cursor-pointer" style="width: 1.5rem; height: 1.5rem" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </div>
      </div>

      <div
        class="alert d-flex justify-content-between align-items-center text-dark alert-dismissible fade show mb-0 alert-danger"
        role="alert" v-if="isErrors && showError">
        <span>Oops! Looks like you've missed out on some fields</span>
        <!-- <button type="button" class="btn"> -->
        <i class="fa-solid fa-times cursor-pointer" @click="showError = false"></i>
        <!-- </button> -->
      </div>
    </div>
    <div v-if="showSubPanel" class="px-4 py-3">
      <div class="mb-3">
        <div class="form-group mb-3">
          <label for="workflowLabel" class="form-label text-uppercase" v-if="selectedFlow == 'addNewTrigger'">Choose a
            automation trigger </label>
          <!-- <Select filter v-model="selectedTrigger" :options="triggerWorkflowsOptions" @change="selectTrigger" optionLabel="label" placeholder="Select Triggers" class="w-100 p-0" /> -->
          <Select v-if="selectedFlow == 'addNewTrigger'" filter v-model="selectedTrigger" @change="selectTrigger"
            :options="triggerWorkflowsOptions" optionLabel="label" optionGroupLabel="label" optionGroupChildren="items"
            placeholder="Select Triggers" class="w-100">
            <template #optiongroup="slotProps">
              <div class="flex items-center">
                <div>{{ slotProps.option.label }}</div>
              </div>
            </template>
            <template #option="slotProps">
              <div class="flex items-center">
                <div class="text-capitalize d-flex align-items-center">
                  <i class="fa me-2" :class="slotProps.option?.icon"></i>
                  <div>{{ slotProps.option?.label }}</div>
                </div>
              </div>
            </template>
            <template #value="slotProps">
              <div v-if="slotProps?.value?.label" class="flex items-center">
                <div class="text-capitalize">{{ slotProps?.value?.label }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
          </Select>
        </div>

        <div class="form-group mb-3">
          <label for="workflowLabel" class="form-label text-uppercase">automation
            {{ selectedFlow == 'AddNewTrigger' ? 'Trigger' : 'Action' }} Name</label>
          <InputText class="w-100" :value="flowTitle" @input="$emit('flowTitle', $event.target.value)"
            :placeholder="this.selectedFlow == 'addNewTrigger' ? 'Trigger Name' : 'Action Name'" />
          <div class="mb-2 mt-1" v-if="isErrors && flowTitle.trim().length > 50">
            <span class="form-text text-danger">Name should be between 1-50 characters..
              {{ flowTitle.trim().length }}/50</span>
          </div>
        </div>

        <div class="col-12" v-if="selectedData?.name == 'updateTicketStatus'">
          <Select filter v-model="selectedData.ticket_status_id"
            @change="selectedData.ticket_status_id = $event.value.id" optionLabel="label" placeholder="Select Status"
            :options="ticket_statuses" class="w-100">
            <template #value="slotProps">
              <div v-if="slotProps?.value" class="flex items-center">
                <div class="text-capitalize">{{ ticket_statuses.find((el) => el.id == slotProps.value).label }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
          </Select>
        </div>

        <template v-if="selectedData?.name == 'assignTicket'">
          <div class="form-group">
            <!-- <label for="">Assignment</label> -->
            <div class="form-check ps-0 form-switch d-flex align-items-center form-switch-cf"
              style="padding-left: 0em !important">
              <label class="form-check-label me-2 text-sm p-0 m-0" for="roundrobinCheck">Round Robin</label>
              <input class="form-check-input mx-2 cursor-pointer" type="checkbox" id="roundrobinCheck"
                v-model="selectedData.roundRobin" @change="sortAssignmentList($event)" />
            </div>
          </div>
          <div class="form-group mb-3">
            <!-- {{ selectedData?.assignedUsers }} -->
            <label for="workflowLabel" class="form-label">Users</label>
            <Select v-if="!selectedData.roundRobin" v-model="singleAssignedUsers"
              @change="checkSingleAssignedUser($event)" :options="assignmentAgentList" optionLabel="agent_name"
              placeholder="Select User" class="w-100 p-0" />
            <MultiSelect v-else :loading="isAssignmentAgentListLoading" v-model="selectedData.assignedUsers"
              display="chip" :options="assignmentAgentList" optionLabel="agent_name" filter
              :maxSelectedLabels="!selectedData.roundRobin ? 1 : null"
              :placeholder="isLoadingTags ? 'Loading...' : 'Select Users'" class="w-100 p-0" />
          </div>
        </template>

        <div class="form-group" v-if="selectedData?.name == 'createNote'">
          <label class="form-label">Note</label>
          <div class="border rounded-3">
            <div class="d-flex align-items-center py-2 border-bottom">
              <div class="mx-3">
                <PlaceHolders :selectedData="selectedData" :nodes="nodes" :type="'note'" @appendPlaceHolder="appendPlaceholderData($event, 'note'), handleInput" />
              </div>
              <i data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Undo"
                class="fa fa-turn-left me-3 cursor-pointer" style="font-size: 16px" @click="undo('note')"
                :class="{ 'text-muted': !canUndo }"></i>
              <i data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Redo"
                class="fa fa-turn-right cursor-pointer" style="font-size: 16px" @click="redo('note')"
                :class="{ 'text-muted': !canRedo }"></i>
            </div>
            <Textarea placeholder="Enter Note" class="w-100 border-0" v-model="selectedData.note" id="note"
              @click="updateCursorPosition($event, 'note')" @keyup="updateCursorPosition($event, 'note'), handleInput"
              @input="updateCursorPosition($event, 'note'), handleInput" name="" cols="30" rows="10"
              style="box-shadow: none" />

            <div class="p-2 text-end text-sm border-top">
              <span>{{ characterCount() }} characters</span> | <span>{{ wordCount() }} words</span>
            </div>
          </div>
        </div>

        <template v-if="selectedData?.name == 'autoReply'">
          <div class="form-group mb-3">
            <label class="form-label">Type</label>
            <Select v-model="selectedData.replyType" :options="['CHAT', 'TICKET']" placeholder="Select"
              class="w-100 p-0" />
          </div>
          <div class="form-group mb-3" v-if="selectedData.replyType == 'TICKET'">
            <label class="form-label">Subject</label>
            <InputText v-model="selectedData.subject" class="w-100" placeholder="Enter subject" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label">Message</label>
            <div class="border rounded-3">
              <div class="d-flex align-items-center py-2 border-bottom">
                <span class="mx-3">
                  <PlaceHolders :selectedData="selectedData" :nodes="nodes" :type="'note'" @appendPlaceHolder="appendPlaceholderData($event, 'message')" />
                </span>
                <i data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Undo"
                  class="fa fa-turn-left me-3 cursor-pointer" style="font-size: 16px" @click="undo('message')"
                  :class="{ 'text-muted': !canUndo }"></i>
                <i data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Redo"
                  class="fa fa-turn-right cursor-pointer" style="font-size: 16px" @click="redo('message')"
                  :class="{ 'text-muted': !canRedo }"></i>
              </div>
              <Textarea placeholder="Enter Message" v-model="selectedData.message" name="" cols="30" rows="10"
                class="w-100 border-0" id="message" style="box-shadow: none"
                @click="updateCursorPosition($event, 'message')" @keyup="updateCursorPosition($event, 'message')"
                @input="updateCursorPosition($event, 'message')" />
            </div>
          </div>
          <div class="form-group mb-3 pb-4">
            <label class="form-label">Agent</label>
            <Select filter v-model="selectedData.agent_ref" :options="agentList" optionLabel="agent_name"
              placeholder="Select User" class="w-100 p-0" />
          </div>
        </template>

        <div class="form-group" v-if="['forwardEmail', 'redirectEmail'].includes(selectedData?.name)">
          <label class="form-label">Email</label>
          <InputText type="email" v-model="selectedData.email" class="w-100" placeholder="Enter Email" />
        </div>

        <div class="form-group" v-if="['addTag', 'removeTag'].includes(selectedData?.name)">
          <label for="workflowLabel" class="form-label">Tags</label>
          <MultiSelect :key="isLoadingTags" :loading="isLoadingTags" v-model="selectedData.selectedTags" display="chip"
            :options="tags" optionLabel="tag" filter :placeholder="isLoadingTags ? 'Loading...' : 'Select Tags'"
            class="w-100 p-0">
            <template #option="slotProps">
              <div class="flex items-center">
                <div>{{ slotProps.option.tag }}</div>
              </div>
            </template>
            <template #footer="slotProps">
              <div class="py-2 px-4">
                <div class="d-flex align-items-center justify-content-center text-xs" v-if="slotProps.options == 0">
                  <span @click="addNewTag()" class="cursor-pointer"><i class="fa fa-plus-circle me-2"></i> Add New
                    Tag</span>
                </div>
              </div>
            </template>
          </MultiSelect>
        </div>

        <!-- <template v-if="selectedData?.name == 'onNewMessage'">
          <div class="form-group">
            <label class="form-label">Channel</label>
            <div class="row">
              <div class="col-6 mb-3">
                <Select filter name="" v-model="selectedData.channelCondition" placeholder="Select Operator" :options="['equals', 'notEquals']" class="w-100">
                  <template #option="slotProps">
                    <div class="flex items-center">
                      <div class="text-capitalize">{{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' : 'Contains' }}</div>
                    </div>
                  </template>
                  <template #value="slotProps">
                    <div v-if="slotProps?.value" class="flex items-center">
                      <div class="text-capitalize">{{ slotProps.value == 'equals' ? 'Is' : slotProps.value == 'notEquals' ? 'Is Not' : 'Contains' }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                </Select>
              </div>
              <div class="col-6">
                <Select filter name="" v-model="selectedData.channel" placeholder="Select Source" :options="['email', 'chat']" class="w-100">
                  <template #option="slotProps">
                    <div class="flex items-center">
                      <div class="text-capitalize">{{ slotProps.option }}</div>
                    </div>
                  </template>
                  <template #value="slotProps">
                    <div v-if="slotProps?.value" class="flex items-center">
                      <div class="text-capitalize">{{ slotProps.value }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                </Select>
              </div>
            </div>
          </div>
          <div class="form-group mb-3">
            <label class="form-label">Type</label>
            <div class="row">
              <div class="col-6">
                <Select filter name="" v-model="selectedData.channelTypeCondition" placeholder="Select Operator" :options="['equals', 'notEquals']" class="w-100">
                  <template #option="slotProps">
                    <div class="flex items-center">
                      <div class="text-capitalize">{{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' : 'Contains' }}</div>
                    </div>
                  </template>
                  <template #value="slotProps">
                    <div v-if="slotProps?.value" class="flex items-center">
                      <div class="text-capitalize">{{ slotProps.value == 'equals' ? 'Is' : slotProps.value == 'notEquals' ? 'Is Not' : 'Contains' }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                </Select>
              </div>
              <div class="col-6">
                <Select filter name="" v-model="selectedData.channelType" placeholder="Select Source" :options="['inbound', 'outbound']" class="w-100">
                  <template #option="slotProps">
                    <div class="flex items-center">
                      <div class="text-capitalize">{{ slotProps.option }}</div>
                    </div>
                  </template>
                  <template #value="slotProps">
                    <div v-if="slotProps?.value" class="flex items-center">
                      <div class="text-capitalize">{{ slotProps.value }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                </Select>
              </div>
            </div>
          </div>
        </template> -->

        <template
          v-if="['ticketCreated', 'onNewMessage', 'onChatEnded', 'conversationRated'].includes(selectedData.name) || selectedData.channel == 'email'">
          <label class="form-label" v-if="selectedData?.filters?.length">Filters</label>
          <div class="row g-0 mb-3 form-group justify-content-between"
            v-for="(filterData, index) in selectedData?.filters" v-bind:key="index">
            <div class="col-11-custom">
              <div class="row w-100 justify-content-between">
                <!-- {{filterData}} -->
                <template
                  v-if="['ticketCreated', 'onNewMessage', 'onChatEnded', 'conversationRated'].includes(selectedData?.name)">
                  <div class="col-4 form-group p-0 pe-2">
                    <Select filter v-model="selectedData.filters[index]"
                      @change="selectedData.filters[index].type == 'timeWithinRange' ? (selectedData.filters[index].startTime = '', selectedData.filters[index].endTime = '') : selectedData.filters[index].value = ''"
                      :options="getFilteredOptions" optionLabel="label" optionGroupLabel="label"
                      optionGroupChildren="items" placeholder="Select Filter" class="w-100">
                      <!-- Option Group Template (for groups) -->
                      <template #optiongroup="slotProps">
                        <div class="flex items-center">
                          {{ slotProps.option.label }}
                        </div>
                        <div v-if="slotProps.option.items.length === 0"
                          class="no-data-message flex items-center text-muted">No data available</div>
                      </template>

                      <!-- Option Template (for individual options) -->
                      <template #option="slotProps">
                        <div class="flex items-center">
                          <div class="text-capitalize">
                            {{ slotProps.option.label }}
                          </div>
                        </div>
                      </template>

                      <!-- Selected Value Display -->
                      <template #value="slotProps">
                        <div v-if="slotProps?.value?.label || slotProps?.value?.type" class="flex items-center">
                          <div class="text-capitalize">
                            <template v-if="slotProps?.value?.label">
                              {{ slotProps?.value?.label }}
                            </template>
                            <template v-else>
                              {{ formatLabel(slotProps?.value?.type) }}
                              {{ slotProps.value.type == 'timeWithinRange' ? '(UTC)' : '' }}
                            </template>
                          </div>
                        </div>
                        <span v-else>
                          {{ slotProps.placeholder }}
                        </span>
                      </template>
                    </Select>
                  </div>
                  <template v-if="checkConditionalFilterBuilder(filterData.type)">
                    <div class="col-4 px-1">
                      <Select v-if="filterData.type" filter name="" v-model="filterData.operator"
                        placeholder="Select Operator"
                        :options="['file', 'image'].includes(checkConditionalFilterBuilder(filterData.type)?.builder?.type) ? ['contains', 'doesNotContain'] : ['equals',  'notEquals', 'contains', 'doesNotContain']"
                        class="w-100">
                        <template #option="slotProps">
                          <div class="flex items-center">
                            <div class="text-capitalize">
                              {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' :  slotProps.option == 'doesNotContain' ? 'Does Not Contain' : 'Contains' }}
                            </div>
                          </div>
                        </template>
                        <template #value="slotProps">
                          <div v-if="slotProps?.value" class="flex items-center">
                            <div class="text-capitalize">
                              {{ slotProps.value == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' :  slotProps.option == 'doesNotContain' ? 'Does Not Contain' : 'Contains' }}
                            </div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                      </Select>
                    </div>
                    <div class="col-4 px-1">
                      <template v-if="filterData.operator">
                        <Select v-if="filterData.operator && ['select', 'radiogroup'].includes(checkConditionalFilterBuilder(filterData.type)?.builder?.type)" filter name="" v-model="filterData.value"
                        placeholder="Select Value"  optionLabel="label" optionValue="value" :options="checkConditionalFilterBuilder(filterData.type)?.items" class="w-100" />
                        <Select v-else-if="filterData.operator && ['toggle', 'checkbox'].includes(checkConditionalFilterBuilder(filterData.type)?.builder?.type)" filter name="" v-model="filterData.value"
                        placeholder="Select Value" :options="getToggleOptions(checkConditionalFilterBuilder(filterData.type))" class="w-100" />
                        <!-- <Select v-else-if="filterData.operator && ['radio'].includes(checkConditionalFilterBuilder(filterData.type)?.builder?.type)" filter name="" v-model="filterData.value"
                        placeholder="Select Value" :options="getRadioOptions(checkConditionalFilterBuilder(filterData.type))" class="w-100" optionLabel="label" optionValue="value" /> -->
                        <InputText v-else-if="['date'].includes(checkConditionalFilterBuilder(filterData.type)?.builder?.type)" placeholder="type here" type="date" class="w-100" v-model="filterData.value" />
                        <InputText v-else-if="['number'].includes(checkConditionalFilterBuilder(filterData.type)?.builder?.type)" placeholder="type here" type="number" class="w-100" v-model="filterData.value" />
                        <InputText v-else placeholder="type here" type="text" class="w-100" v-model="filterData.value" />
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="['subject', 'body'].includes(filterData.type)">
                      <div class="col-4 form-group p-0 pe-2">
                        <Select filter name="" v-model="filterData.operator" placeholder="Select Operator"
                          :options="filterData.type == 'subject' ? ['equals', 'contains', 'notEquals', 'doesNotContain'] : ['contains', 'doesNotContain']"
                          class="w-100">
                          <template #option="slotProps">
                            <div class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' :  slotProps.option == 'doesNotContain' ? 'Does Not Contain' :  'Contains'  }}
                              </div>
                            </div>
                          </template>
                          <template #value="slotProps">
                            <div v-if="slotProps?.value" class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.value == 'equals' ? 'Is' : slotProps.value == 'notEquals' ? 'Is Not' : slotProps.value == 'doesNotContain' ? 'Does Not Contain' : 'Contains' }}
                              </div>
                            </div>
                            <span v-else>
                              {{ slotProps.placeholder }}
                            </span>
                          </template>
                        </Select>
                      </div>
                      <div class="col-4 form-group p-0">
                        <input v-if="filterData.operator" type="text" placeholder="Type here..."
                          v-model="filterData.value" class="form-control" />
                      </div>
                    </template>
                    <template v-else-if="['from', 'to', 'senderEmail', 'senderName'].includes(filterData.type)">
                      <div class="col-4 p-0 pe-2">
                        <Select filter name="" v-model="filterData.operator" placeholder="Select Operator"
                          :options="['equals', 'contains', 'notEquals', 'doesNotContain']" class="w-100">
                          <template #option="slotProps">
                            <div class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' :  slotProps.option == 'doesNotContain' ? 'Does Not Contain' :  'Contains' }}
                              </div>
                            </div>
                          </template>
                          <template #value="slotProps">
                            <div v-if="slotProps?.value" class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.value == 'equals' ? 'Is' : slotProps.value == 'notEquals' ? 'Is Not' : slotProps.value == 'doesNotContain' ? 'Does Not Contain' : 'Contains' }}
                              </div>
                            </div>
                            <span v-else>
                              {{ slotProps.placeholder }}
                            </span>
                          </template>
                        </Select>
                      </div>
                      <div class="col-4 p-0">
                        <InputText v-if="filterData.operator" type="text" placeholder="Type here..."
                          v-model="filterData.value" class="form-control" />
                      </div>
                    </template>
                    <template v-else-if="['rating', '{{message.csat_score}}'].includes(filterData.type)">
                      <div class="col-4 p-0 pe-2">
                        <Select
                          @change=" $event.value.option == 'equals' ? selectedData.filters[index].value = [] : selectedData.filters[index].value = ''"
                          filter name="" v-model="filterData.operator" placeholder="Select Operator"
                          :options="['equals', 'notEquals', 'greaterThanOrEqual', 'lessThanOrEqual']" class="w-100">
                          <template #option="slotProps">
                            <div class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'greaterThanOrEqual' ? 'Greater than or is' : slotProps.option == 'lessThanOrEqual' ? 'Less than or is' : 'Is Not'}}
                              </div>
                            </div>
                          </template>
                          <template #value="slotProps">
                            <div v-if="slotProps?.value" class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.value == 'equals' ? 'Is' : slotProps.value == 'greaterThanOrEqual' ? 'Greater than or is' : slotProps.option == 'lessThanOrEqual' ? 'Less than or is' : 'Is Not'  }}
                              </div>
                            </div>
                            <span v-else>
                              {{ slotProps.placeholder }}
                            </span>
                          </template>
                        </Select>
                      </div>
                      <div class="col-4 p-0">
                        <template v-if="filterData.operator">
                          <input v-if="filterData.operator !== 'equals'" type="text" placeholder="Type here..."
                            v-model="filterData.value" class="form-control" />
                          <MultiSelect v-else v-model="filterData.value" display="chip" :options="['1','2','3','4','5']"
                            filter :placeholder="'Select CSAT Scores'" class="w-100 p-0" />
                        </template>
                      </div>
                    </template>
                    <template v-else-if="['channel', 'channelType'].includes(filterData.type)">
                      <div class="col-4 p-0 pe-2">
                        <div class="form-group">
                          <Select filter name="" v-model="filterData.operator" placeholder="Select Operator"
                            :options="['equals', 'notEquals']" class="w-100">
                            <template #option="slotProps">
                              <div class="flex items-center">
                                <div class="text-capitalize">
                                  {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' : 'Contains' }}
                                </div>
                              </div>
                            </template>
                            <template #value="slotProps">
                              <div v-if="slotProps?.value" class="flex items-center">
                                <div class="text-capitalize">
                                  {{ slotProps.value == 'equals' ? 'Is' : slotProps.value == 'notEquals' ? 'Is Not' : 'Contains' }}
                                </div>
                              </div>
                              <span v-else>
                                {{ slotProps.placeholder }}
                              </span>
                            </template>
                          </Select>
                        </div>
                      </div>
                      <div class="col-4 p-0">
                        <Select v-if="filterData.operator" filter name="" v-model="filterData.value"
                          placeholder="Select Source"
                          :options="filterData.type == 'channel' ? ['email', 'chat'] : ['inbound', 'outbound']"
                          class="w-100">
                          <template #option="slotProps">
                            <div class="flex items-center">
                              <div class="text-capitalize">{{ slotProps.option }}</div>
                            </div>
                          </template>
                          <template #value="slotProps">
                            <div v-if="slotProps?.value" class="flex items-center">
                              <div class="text-capitalize">{{ slotProps.value }}</div>
                            </div>
                            <span v-else>
                              {{ slotProps.placeholder }}
                            </span>
                          </template>
                        </Select>
                      </div>
                    </template>
                    <template v-else-if="['isAssignedTo', 'isUnassigned'].includes(filterData.type)">
                      <div class="col-4 p-0 pe-2">
                        <div class="form-group">
                          <Select filter name="" v-model="filterData.operator" placeholder="Select Operator"
                            :options="['equals', 'notEquals']" class="w-100">
                            <template #option="slotProps">
                              <div class="flex items-center">
                                <div class="text-capitalize">
                                  {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' : 'Contains' }}
                                </div>
                              </div>
                            </template>
                            <template #value="slotProps">
                              <div v-if="slotProps?.value" class="flex items-center">
                                <div class="text-capitalize">
                                  {{ slotProps.value == 'equals' ? 'Is' : slotProps.value == 'notEquals' ? 'Is Not' : 'Contains' }}
                                </div>
                              </div>
                              <span v-else>
                                {{ slotProps.placeholder }}
                              </span>
                            </template>
                          </Select>
                        </div>
                      </div>

                      <div class="col-4 p-0">
                        <MultiSelect v-if="filterData.operator" :loading="isAssignmentAgentListLoading"
                          v-model="filterData.value" display="chip" :options="assignmentAgentList"
                          optionLabel="agent_name" filter :placeholder="isLoadingTags ? 'Loading...' : 'Select Users'"
                          class="w-100 p-0" />
                      </div>
                    </template>
                    <template v-else-if="filterData.type == 'isReceivedOnDate'">
                      <div class="col-4 p-0 pe-2">
                        <Select filter name="" v-model="filterData.operator" placeholder="Select Operator"
                          :options="['equals', 'notEquals']" class="w-100">
                          <template #option="slotProps">
                            <div class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' : 'Contains' }}
                              </div>
                            </div>
                          </template>
                          <template #value="slotProps">
                            <div v-if="slotProps?.value" class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.value == 'equals' ? 'Is' : slotProps.value == 'notEquals' ? 'Is Not' : 'Contains' }}
                              </div>
                            </div>
                            <span v-else>
                              {{ slotProps.placeholder }}
                            </span>
                          </template>
                        </Select>
                      </div>
                      <div class="col-4 p-0">
                        <input v-if="filterData.operator" type="date" v-model="filterData.value" class="form-control" />
                      </div>
                    </template>
                    <template v-else-if="filterData.type == 'receivedDay'">
                      <div class="col-4 p-0 pe-2">
                        <Select filter name="" v-model="filterData.operator" placeholder="Select Operator"
                          :options="['equals', 'notEquals']" class="w-100">
                          <template #option="slotProps">
                            <div class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' : 'Contains' }}
                              </div>
                            </div>
                          </template>
                          <template #value="slotProps">
                            <div v-if="slotProps?.value" class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.value == 'equals' ? 'Is' : slotProps.value == 'notEquals' ? 'Is Not' : 'Contains' }}
                              </div>
                            </div>
                            <span v-else>
                              {{ slotProps.placeholder }}
                            </span>
                          </template>
                        </Select>
                      </div>
                      <div class="col-4 p-0">
                        <select v-if="filterData.operator" name="" v-model="filterData.value"
                          class="form-select text-capitalize">
                          <option value="" disabled>Select</option>
                          <option :value="day" v-for="(day, i) in daysOfWeek" v-bind:key="i">{{ day }}</option>
                        </select>
                      </div>
                    </template>
                    <template v-else-if="filterData.type == 'timeWithinRange'">
                      <div class="col-4 p-0 pe-2">
                        <input type="time" v-model="filterData.startTime" class="form-control" />
                      </div>
                      <div class="col-4 p-0">
                        <input type="time" v-model="filterData.endTime" class="form-control" />
                      </div>
                    </template>

                    <template v-else-if="['endedBy'].includes(filterData.type)">
                      <div class="col-4 p-0 pe-2">
                        <Select filter name="" v-model="filterData.operator" placeholder="Select Operator"
                          :options="['equals', 'notEquals']" class="w-100">
                          <template #option="slotProps">
                            <div class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' : 'Contains' }}
                              </div>
                            </div>
                          </template>
                          <template #value="slotProps">
                            <div v-if="slotProps?.value" class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.value == 'equals' ? 'Is' : slotProps.value == 'notEquals' ? 'Is Not' : 'Contains' }}
                              </div>
                            </div>
                            <span v-else>
                              {{ slotProps.placeholder }}
                            </span>
                          </template>
                        </Select>
                      </div>
                      <div class="col-4 p-0">
                        <Select v-if="filterData.operator" filter name="" v-model="filterData.value"
                          placeholder="Select Ended By" :options="['visitor', 'system']" class="w-100">
                          <template #option="slotProps">
                            <div class="flex items-center">
                              <div class="text-capitalize">{{ slotProps.option }}</div>
                            </div>
                          </template>
                          <template #value="slotProps">
                            <div v-if="slotProps?.value" class="flex items-center">
                              <div class="text-capitalize">{{ slotProps.value }}</div>
                            </div>
                            <span v-else>
                              {{ slotProps.placeholder }}
                            </span>
                          </template>
                        </Select>
                      </div>
                    </template>
                    <template v-else>
                      <div class="col-4 p-0 pe-2">
                        <Select v-if="filterData.type" filter name="" v-model="filterData.operator" placeholder="Select Operator"
                          :options="['equals',  'notEquals', 'contains', 'doesNotContain']"
                          class="w-100">
                          <template #option="slotProps">
                            <div class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' :  slotProps.option == 'doesNotContain' ? 'Does Not Contain' : 'Contains' }}
                              </div>
                            </div>
                          </template>
                          <template #value="slotProps">
                            <div v-if="slotProps?.value" class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.value == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' :  slotProps.option == 'doesNotContain' ? 'Does Not Contain' : 'Contains' }}
                              </div>
                            </div>
                            <span v-else>
                              {{ slotProps.placeholder }}
                            </span>
                          </template>
                        </Select>
                      </div>
                      <div class="col-4 p-0">
                        <InputText v-if="filterData.operator" type="text" placeholder="Type here..."
                        v-model="filterData.value" class="form-control" />
                      </div>
                    </template>
                    <div class="col-12" v-if="filterData.type == 'lastReplyAfter'">
                      <input type="time" v-model="filterData.value" class="form-control p-0" />
                    </div>
                  </template>
                </template>
              </div>
            </div>
            <div class="col-1-custom text-secondary d-flex align-items-center justify-content-center px-0">
              <i class="fa-solid fa-trash cursor-pointer" @click="removeFilter(index)"></i>
            </div>
          </div>
        </template>

        <!-- showCustomFilter -->
        <!-- <div class="form-group" v-if="(showCustomFilter && selectedData.name == 'ticketCreated') || (showCustomFilter && selectedData.channel == 'email' && selectedData.name == 'onNewMessage')">
                    <label class="form-label text-danger"><span class="text-danger">Select Filter *</span></label>
                    <div class="row align-items-center">
                        <div class="col-11-custom">
                            <select class="form-select cursor-pointer" @change="appendFilter($event.target.value)">
                                <option value="" selected  disabled>Please Select a filter</option>
                                <optgroup label="Recipients" v-if="selectedData.name == 'ticketCreated'">
                                    <option value="from">From</option>
                                </optgroup>
                                <optgroup label="Sender" v-if="selectedData.name == 'onNewMessage'">
                                    <option value="senderName">Name</option>
                                    <option value="senderEmail">Email</option>
                                </optgroup>
                                <optgroup label="Content" v-if="['onNewMessage', 'ticketCreated'].includes(selectedData?.name)">
                                    <option value="subject">Subject</option>
                                    <option value="body">Body</option>
                                </optgroup>
                                <optgroup label="Team Members" v-if="selectedData.name == 'onNewMessage'">
                                    <option value="isAssignedTo">Is Assigned To</option>
                                </optgroup>
                                <optgroup label="Time" v-if="['onNewMessage', 'ticketCreated'].includes(selectedData?.name)">
                                    <option value="isReceivedOnDate">Message is received on</option>
                                    <option value="receivedDay">Day Is</option>
                                    <option value="timeWithinRange">Time is between</option>
                                </optgroup>
                            </select>
                        </div>
                        <div class="col-1-custom d-flex px-0 align-items-center justify-content-start text-danger">
                            <i class="fa fa-trash cursor-pointer" @click="showCustomFilter = !showCustomFilter"></i>
                        </div>
                    </div>
                </div> -->

        <div v-if="['ticketCreated', 'onNewMessage', 'onChatEnded', 'conversationRated'].includes(selectedData.name)"
          class="text-sm mb-3 pb-3">
          <span class="cursor-pointer text-curious-blue-700 text-primary"
            @click="checkFilterValidation() ? appendCustomFilter() : showFilterAlert()">
            <i class="fa-regular fa-circle-plus"></i> Add filters
          </span>
        </div>

        <template v-if="selectedData?.name == 'webhook'">
          <div class="form-group">
            <label for="webhookUrl" class="form-label">Method</label>
            <select class="form-select mb-3" v-model="selectedData.method">
              <option value="post">Post</option>
              <option value="get">Get</option>
            </select>
          </div>
          <div class="form-group mb-3">
            <label for="webhookUrl" class="form-label">Url</label>
            <InputText type="text" v-model="selectedData.api_url" placeholder="Webhook Url" class="w-100" />
          </div>
          <div class="form-group mb-3">
            <label for="webhookUrl" class="form-label">Custom Data</label>
            <div class="row align-items-center justify-content-between" v-for="(data, i) in selectedData?.customData"
              v-bind:key="i">
              <div class="col-5 mb-3 p-0 pe-2">
                <InputText type="text" v-model="data.key" placeholder="Key" class="w-100" />
              </div>
              <div class="col-6 mb-3 p-0 pe-2">
                <InputText type="text" v-model="data.value" placeholder="Value" class="w-100" />
              </div>
              <div class="col text-secondary text-end">
                <i class="fa-solid fa-trash mb-3 cursor-pointer" @click="removeWebhookCustomData(i)"></i>
              </div>
            </div>
            <div class="text-sm cursor-pointer">
              <span @click="appendWebhookCustomData({key:'', value:''})">
                <i class="fa-regular fa-circle-plus me-2 text-curious-blue-700 text-primary"></i>
                <span class="text-curious-blue-700 text-primary">Add Item</span>
              </span>
            </div>
          </div>
          <div class="form-group">
            <label for="webhookUrl" class="form-label">Headers</label>
            <div class="row align-items-center justify-content-between" v-for="(data, i) in selectedData?.headers"
              v-bind:key="i">
              <div class="col-5 mb-3 p-0 pe-2">
                <InputText type="text" v-model="data.key" placeholder="Key" class="w-100" />
              </div>
              <div class="col-6 mb-3 p-0 pe-2">
                <InputText type="text" v-model="data.value" placeholder="Value" class="w-100" />
              </div>
              <div class="col text-secondary text-end">
                <i class="fa-solid fa-trash mb-3 cursor-pointer" @click="removeCustomHeaders(i)"></i>
              </div>
            </div>
            <div class="text-sm cursor-pointer pb-4">
              <span @click="appendWebhookHeaders({ key: '', value: '' })">
                <i class="fa-regular me-2 fa-circle-plus text-curious-blue-700 text-primary"></i>
                <span class="text-curious-blue-700 text-primary">Add Item</span>
              </span>
            </div>
          </div>
        </template>

        <template v-if="selectedData?.name == 'fetchConversation'">
          <div class="form-group mb-3" v-if="!selectedData.sendToModalSupport">
            <label for="webhookUrl" class="form-label">Api Url</label>
            <InputText v-model="selectedData.api_url" type="text" id="webhookUrl" placeholder="Webhook Url"
              class="w-100" />
          </div>
          <div class="form-group">
            <label for="webhookUrl" class="form-label">Headers</label>
            <div class="row align-items-center justify-content-between" v-for="(data, i) in selectedData?.headers"
              v-bind:key="i">
              <div class="col-5 mb-3 p-0 pe-2">
                <InputText v-model="data.key" type="text" placeholder="Key" class="w-100" />
              </div>
              <div class="col-6 mb-3 p-0 pe-2">
                <InputText v-model="data.value" type="text" placeholder="Value" class="w-100" />
              </div>
              <div class="col text-secondary text-end">
                <i class="fa-solid fa-trash mb-3 cursor-pointer" @click="removeCustomHeaders(i)"></i>
              </div>
            </div>
            <div class="text-sm cursor-pointer pb-4">
              <span @click="appendFetchConvesationHeaders({ key: '', value: '' })" class="text-primary"><i
                  class="fa-regular fa-plus-circle text-primary"></i> Add Item</span>
            </div>
          </div>
        </template>

        <template v-if="['chatgpt', 'claude', 'gemini', 'llama', 'modalai'].includes(selectedData?.name)">

          <div class="form-group mb-3" v-if="['chatgpt'].includes(selectedData?.name)">
            <label for="">Request Type <span class="text-danger">*</span></label>
            <Select filter name="" v-model="selectedData.lookupSource"
              placeholder="Select Lookup Source"
              :options="['WEB_API']" class="w-100">
              <template #option="slotProps">
                <div class="flex items-center">
                  <div class="">
                    {{
                      slotProps.option == 'WEB_API'
                        ? 'General'
                        : slotProps.option == 'KNOWLEDGE_BASE'
                        ? 'Knowledge base lookup'
                        : slotProps.option == 'SOP'
                        ? 'SOP' : 
                        ''
                    }}
                  </div>
                </div>
              </template>
              <template #value="slotProps">
                <div v-if="slotProps?.value" class="flex items-center">
                  <div class="">
                    {{
                      slotProps.value == 'WEB_API'
                        ? 'General'
                        : slotProps.value == 'KNOWLEDGE_BASE'
                        ? 'Knowledge base lookup'
                        : slotProps.value == 'SOP'
                        ? 'SOP'
                        : ''
                    }}
                  </div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
            </Select>
           

            <div class="text-sm">
              <span class="form-text">Select a Lookup Souce</span>
            </div>
          </div>

          <template v-if="['claude', 'gemini', 'llama'].includes(selectedData?.name) || (selectedData.name =='chatgpt' &&  selectedData?.lookupSource == 'WEB_API')">
            <div class="form-group mb-3" v-if="['chatgpt', 'claude', 'gemini', 'llama'].includes(selectedData?.name)">
              <label for="">Model <span class="text-danger">*</span></label>
              <Select v-if="selectedData?.name == 'chatgpt'" filter name="" v-model="selectedData.model"
                placeholder="Select Model"
                :options="['gpt-3.5-turbo', 'gpt-4', 'gpt-4o', 'gpt-4o-mini', 'gpt-4-turbo', 'o1-preview']" class="w-100">
                <template #option="slotProps">
                  <div class="flex items-center">
                    <div class="text-capitalize">
                      {{
                        slotProps.option == 'gpt-3.5-turbo'
                          ? 'GPT 3.5 Turbo'
                          : slotProps.option == 'gpt-4-turbo'
                          ? 'GPT 4 Turbo'
                          : slotProps.option == 'gpt-4'
                          ? 'GPT 4'
                          : slotProps.option == 'gpt-4o-mini'
                          ? 'GPT 4o Mini'
                          : slotProps.option == 'gpt-4o'
                          ? 'GPT 4o'
                          : slotProps.option == 'o1-preview'
                          ? 'o1 Preview'
                          : ''
                      }}
                    </div>
                  </div>
                </template>
                <template #value="slotProps">
                  <div v-if="slotProps?.value" class="flex items-center">
                    <div class="text-capitalize">
                      {{
                        slotProps.value == 'gpt-3.5-turbo'
                          ? 'GPT 3.5 Turbo'
                          : slotProps.value == 'gpt-4-turbo'
                          ? 'GPT 4 Turbo'
                          : slotProps.value == 'gpt-4'
                          ? 'GPT 4'
                          : slotProps.value == 'gpt-4o-mini'
                          ? 'GPT 4o Mini'
                          : slotProps.value == 'gpt-4o'
                          ? 'GPT 4o'
                          : slotProps.value == 'o1-preview'
                          ? 'o1 Preview'
                          : ''
                      }}
                    </div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
              </Select>
              <Select v-if="selectedData?.name == 'claude'" filter name="" v-model="selectedData.model"
                placeholder="Select Model"
                :options="['claude-3-haiku-20240307', 'claude-3-sonnet-20240229', 'claude-3-opus-20240229', 'claude-3-5-sonnet-20240620']"
                class="w-100">
                <template #option="slotProps">
                  <div class="flex items-center">
                    <div class="text-capitalize">
                      {{
                      slotProps.option == 'claude-3-haiku-20240307' ? 'Claude 3 Haiku 20240307' :
                      slotProps.option == 'claude-3-sonnet-20240229' ? 'Claude 3 Sonnet 20240229' : 
                      slotProps.option == 'claude-3-opus-20240229' ? 'Claude 3 Opus 20240229' :
                      slotProps.option == 'claude-3-5-sonnet-20240620' ? 'Claude 3  5 Sonnet 20240620' : '' }}
                    </div>
                  </div>
                </template>
                <template #value="slotProps">
                  <div v-if="slotProps?.value" class="flex items-center">
                    <div class="text-capitalize">
                      {{
                      slotProps.value == 'claude-3-haiku-20240307' ? 'Claude 3 Haiku 20240307' :
                      slotProps.value == 'claude-3-sonnet-20240229' ? 'Claude 3 Sonnet 20240229' : 
                      slotProps.value == 'claude-3-opus-20240229' ? 'Claude 3 Opus 20240229' :
                      slotProps.value == 'claude-3-5-sonnet-20240620' ? 'Claude 3  5 Sonnet 20240620' : '' }}
                    </div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
              </Select>
              <Select v-if="selectedData?.name == 'gemini'" filter name="" v-model="selectedData.model"
                placeholder="Select Model" :options="['gemini-1.5-flash']" class="w-100">
                <template #option="slotProps">
                  <div class="flex items-center">
                    <div class="text-capitalize">
                      {{ slotProps.option == 'gemini-1.5-flash' ? 'Gemini 1.5 Flash' : '' }}
                    </div>
                  </div>
                </template>
                <template #value="slotProps">
                  <div v-if="slotProps?.value" class="flex items-center">
                    <div class="text-capitalize">
                      {{ slotProps.value == 'gemini-1.5-flash' ? 'Gemini 1.5 Flash' : '' }}
                    </div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
              </Select>
              <Select v-if="selectedData?.name == 'llama'" filter name="" v-model="selectedData.model"
                placeholder="Select Model" :options="['llama3.1-405b', 'llama3.2-11b-vision', 'llama3.2-90b-vision']"
                class="w-100">
                <template #option="slotProps">
                  <div class="flex items-center">
                    <div class="text-capitalize">
                      {{ slotProps.option == 'llama3.1-405b' ? 'Llama 3.1-405b' : 
                        slotProps.option ==  'llama3.2-11b-vision' ? 'Llama 3.2 11b vision' :  
                        slotProps.option ==  'llama3.2-90b-vision' ? 'Llama 3.2 90b vision' : 
                        '' }}
                    </div>
                  </div>
                </template>
                <template #value="slotProps">
                  <div v-if="slotProps?.value" class="flex items-center">
                    <div class="text-capitalize">
                      {{ slotProps.value == 'llama3.1-405b' ? 'Llama 3.1-405b' : 
                        slotProps.value ==  'llama3.2-11b-vision' ? 'Llama 3.2 11b vision' :  
                        slotProps.value ==  'llama3.2-90b-vision' ? 'Llama 3.2 90b vision' : 
                        '' }}
                    </div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
              </Select>

              <div class="text-sm">
                <span class="form-text">Select a Model</span>
              </div>
            </div>
          </template>

          <div class="form-group mb-3">
            <label for="">Prompt <span class="text-danger">*</span></label>
            <div class="border rounded-3">
              <div class="d-flex align-items-center py-2 border-bottom">
                <span class="mx-3">
                  <PlaceHolders :selectedData="selectedData" :nodes="nodes" :type="'note'" @appendPlaceHolder="appendPlaceholderData($event, 'prompt')" />
                </span>
                <i data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Undo"
                  class="fa fa-turn-left me-3 cursor-pointer" style="font-size: 14px" @click="undo('prompt')"
                  :class="{ 'text-muted': !canUndo }"></i>
                <i data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Redo"
                  class="fa fa-turn-right cursor-pointer" style="font-size: 14px" @click="redo('prompt')"
                  :class="{ 'text-muted': !canRedo }"></i>
              </div>
              <Textarea class="w-100 border-0" name="" rows="4" id="prompt" style="box-shadow: none"
                v-model="selectedData.prompt" @click="updateCursorPosition($event, 'prompt')"
                @keyup="updateCursorPosition($event, 'prompt')" @input="updateCursorPosition($event, 'prompt')"
                placeholder="Please Input" />
            </div>
            <div class="text-sm">
              <div class="form-text">Ask
                {{ selectedData?.name == 'chatgpt' ? 'GPT' : selectedData?.name == 'claude' ? 'Claude' : selectedData?.name == 'llama' ? 'Llama' : selectedData?.name == 'gemini' ? 'Gemini' : 'Modal' }}
                for anything to generate completions for. E.g. Generate a response for
                customer message.
              </div>
            </div>
            <div class="text-danger form-text" v-if="isErrors && selectedData.prompt.trim() == ''">
              Prompt is required
            </div>
          </div>
          <div class="form-group" v-if="['claude', 'gemini', 'llama'].includes(selectedData?.name) || (selectedData.name =='chatgpt' &&  selectedData?.lookupSource == 'WEB_API')">
            <label for="">Temperature (Optional)</label>
            <InputText class="w-100" type="number" id="temperature" v-model="selectedData.temperature"
              placeholder="Please Input" />
            <div class="text-sm pb-2">
              <div class="form-text">Value between 0 and 1, 0.8 will make the output more random, while 0.2 will make it
                more focused and deterministic.</div>
            </div>
          </div>
          <div class="form-group mb-3" v-if="['claude', 'llama'].includes(selectedData?.name) || (selectedData.name =='chatgpt' &&  selectedData?.lookupSource == 'WEB_API')">
            <label for="">Max Tokens (Optional)</label>
            <InputText class="w-100" type="number" v-model="selectedData.max_tokens" placeholder="Please Input" />
          </div>
          <div class="form-group" v-if="['claude', 'llama'].includes(selectedData?.name) || (selectedData.name =='chatgpt' &&  selectedData?.lookupSource == 'WEB_API')"
            :class="['claude'].includes(selectedData.name) ? 'mb-3' : 'mb-5'">
            <label for="">Top P (Optional)</label>
            <InputText class="w-100" type="number" v-model="selectedData.top_p" placeholder="Please Input" />
          </div>
          <div class="form-group mb-5" v-if="['claude'].includes(selectedData.name)">
            <label for="">Top K (Optional)</label>
            <InputText class="w-100" type="number" v-model="selectedData.top_k" placeholder="Please Input" />
          </div>
          <template v-if="['gemini'].includes(selectedData.name)">
            <div class="form-group mb-3">
              <label for="">Max Output Tokens (Optional)</label>
              <InputText class="w-100" type="number" v-model="selectedData.maxOutputTokens"
                placeholder="Please Input" />
            </div>
            <div class="form-group mb-5">
              <label for="">Stop Sequences (Optional)</label>
              <AutoComplete v-model="selectedData.stopSequences" inputId="multiple-ac-2" multiple fluid
                :typeahead="false" class="w-100 autocomplete-input" placeholder="Please input and hit enter"
                @change="onChangeStopSequences" />
            </div>
          </template>
        </template>

        <template v-if="selectedData?.name == 'condition'">
          <div class="form-group">
            <label for="">Branches</label>
            <div class="border mb-3 p-3 position-relative" v-bind:key="i" v-for="(branch, i) in selectedData?.branches">
              <button v-if="i !== 0 && branch?.condition?.type !== 'NONE'" @click="removeBranch(i)"
                class="btn bg-light border position-absolute rounded-circle p-0 d-flex align-items-center justify-content-center"
                style="top: -15px; right: -15px; height: 25px; width: 25px">
                <i class="fa fa-times"></i>
              </button>

              <div clas="p-2" v-if="branch?.condition?.type == 'NONE'">
                <div class="fw-bold text-sm">None Branch</div>
                <p class="text-xs">When no condition is met</p>
              </div>
              <div class="mb-3">
                <InputText type="text" placeholder="Branch Name" v-model="branch.branch_name" class="w-100" />
              </div>
              <div class="border bg-light p-2" v-if="branch?.condition?.type !== 'NONE'">
                <div class="text-end mb-2 p-0 position-relative">
                  <button class="btn rounded-0" :class="{ 'btn-secondary btn-xs': branch.condition.type == 'AND' }"
                    @click="branch.condition.type = 'AND'">AND</button>
                  <button class="btn rounded-0" :class="{ 'btn-secondary btn-xs': branch.condition.type == 'OR' }"
                    @click="branch.condition.type = 'OR'">OR</button>
                </div>
                <div class="row mb-2" v-for="(condition, idx) in branch?.condition?.conditions" v-bind:key="idx">
                  <div class="col-11-custom">
                    <div class="row g-0">
                      <div class="col-4 px-1">
                        <Select filter v-model="condition.type" @change="condition.type = $event.value.type"
                          :options="getConditionalFilters" optionLabel="label" optionGroupLabel="label"
                          optionGroupChildren="items" placeholder="Select Filter" class="w-100">
                          <!-- Option Group Template (for groups) -->
                          <template #optiongroup="slotProps">
                            <div class="flex items-center">
                              {{ slotProps.option.label }}
                            </div>
                            <div v-if="slotProps.option.items.length === 0"
                              class="no-data-message flex items-center text-muted">No data available</div>
                          </template>

                          <!-- Option Template (for individual options) -->
                          <template #option="slotProps">
                            <div class="flex items-center">
                              <div class="text-capitalize">
                                {{ slotProps.option.label }}
                              </div>
                            </div>
                          </template>

                          <!-- Selected Value Display -->
                          <template #value="slotProps">

                            <div v-if="slotProps?.value" class="flex items-center">
                              <div class="text-capitalize">
                                {{ getConditionFilterLabel(slotProps) }}
                              </div>
                            </div>
                            <span v-else>
                              {{ slotProps.placeholder }}
                            </span>
                          </template>
                        </Select>
                      </div>
                      <template v-if="checkConditionalFilterBuilder(condition.type)">
                        <div class="col-4 px-1">
                          <Select v-if="condition.type" filter name="" v-model="condition.operator"
                            placeholder="Select Operator"
                            :options="['file', 'image'].includes(checkConditionalFilterBuilder(condition.type)?.builder?.type) ? ['contains', 'doesNotContain'] : ['equals',  'notEquals', 'contains', 'doesNotContain']"
                            class="w-100">
                            <template #option="slotProps">
                              <div class="flex items-center">
                                <div class="text-capitalize">
                                  {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' :  slotProps.option == 'doesNotContain' ? 'Does Not Contain' : 'Contains' }}
                                </div>
                              </div>
                            </template>
                            <template #value="slotProps">
                              <div v-if="slotProps?.value" class="flex items-center">
                                <div class="text-capitalize">
                                  {{ slotProps.value == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' :  slotProps.option == 'doesNotContain' ? 'Does Not Contain' : 'Contains' }}
                                </div>
                              </div>
                              <span v-else>
                                {{ slotProps.placeholder }}
                              </span>
                            </template>
                          </Select>
                        </div>
                        <div class="col-4 px-1">
                          <template v-if="condition.operator">
                            <Select v-if="condition.operator && ['select', 'radiogroup'].includes(checkConditionalFilterBuilder(condition.type)?.builder?.type)" filter name="" v-model="condition.value"
                            placeholder="Select Value"  optionLabel="label" optionValue="value" :options="checkConditionalFilterBuilder(condition.type)?.items" class="w-100" />
                            <Select v-else-if="condition.operator && ['toggle', 'checkbox'].includes(checkConditionalFilterBuilder(condition.type)?.builder?.type)" filter name="" v-model="condition.value"
                            placeholder="Select Value" :options="getToggleOptions(checkConditionalFilterBuilder(condition.type))" class="w-100" />
                            <!-- <Select v-else-if="condition.operator && ['radio'].includes(checkConditionalFilterBuilder(condition.type)?.builder?.type)" filter name="" v-model="condition.value"
                            placeholder="Select Value" :options="getRadioOptions(checkConditionalFilterBuilder(condition.type))" class="w-100" optionLabel="label" optionValue="value" /> -->
                            <InputText v-else-if="['date'].includes(checkConditionalFilterBuilder(condition.type)?.builder?.type)" placeholder="type here" type="date" class="w-100" v-model="condition.value" />
                            <InputText v-else-if="['number'].includes(checkConditionalFilterBuilder(condition.type)?.builder?.type)" placeholder="type here" type="number" class="w-100" v-model="condition.value" />
                            <InputText v-else placeholder="type here" type="text" class="w-100" v-model="condition.value" />
                          </template>
                        </div>
                      </template>
                      <template v-else-if="['channel', 'channelType'].includes(condition.type)">
                        <div class="col-4 p-0 pe-2">
                          <div class="form-group" v-if="condition.type">
                            <Select filter name="" v-model="condition.operator" placeholder="Select Operator"
                              :options="['equals', 'notEquals']" class="w-100">
                              <template #option="slotProps">
                                <div class="flex items-center">
                                  <div class="text-capitalize">
                                    {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' : 'Contains' }}
                                  </div>
                                </div>
                              </template>
                              <template #value="slotProps">
                                <div v-if="slotProps?.value" class="flex items-center">
                                  <div class="text-capitalize">
                                    {{ slotProps.value == 'equals' ? 'Is' : slotProps.value == 'notEquals' ? 'Is Not' : 'Contains' }}
                                  </div>
                                </div>
                                <span v-else>
                                  {{ slotProps.placeholder }}
                                </span>
                              </template>
                            </Select>
                          </div>
                        </div>
                        <div class="col-4 p-0">
                          <Select v-if="condition.operator" filter name="" v-model="condition.value"
                            placeholder="Select Source"
                            :options="condition.type == 'channel' ? ['email', 'chat'] : ['inbound', 'outbound']"
                            class="w-100">
                            <template #option="slotProps">
                              <div class="flex items-center">
                                <div class="text-capitalize">{{ slotProps.option }}</div>
                              </div>
                            </template>
                            <template #value="slotProps">
                              <div v-if="slotProps?.value" class="flex items-center">
                                <div class="text-capitalize">{{ slotProps.value }}</div>
                              </div>
                              <span v-else>
                                {{ slotProps.placeholder }}
                              </span>
                            </template>
                          </Select>
                        </div>
                      </template>
                      <template v-else-if="['endedBy'].includes(condition.type)">
                        <div class="col-4 p-0 pe-2">
                          <Select v-if="condition.type" filter name="" v-model="condition.operator"
                            placeholder="Select Operator" :options="['equals', 'notEquals']" class="w-100">
                            <template #option="slotProps">
                              <div class="flex items-center">
                                <div class="text-capitalize">
                                  {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' : 'Contains' }}
                                </div>
                              </div>
                            </template>
                            <template #value="slotProps">
                              <div v-if="slotProps?.value" class="flex items-center">
                                <div class="text-capitalize">
                                  {{ slotProps.value == 'equals' ? 'Is' : slotProps.value == 'notEquals' ? 'Is Not' : 'Contains' }}
                                </div>
                              </div>
                              <span v-else>
                                {{ slotProps.placeholder }}
                              </span>
                            </template>
                          </Select>
                        </div>
                        <div class="col-4 p-0">
                          <Select v-if="condition.operator" filter name="" v-model="condition.value"
                            placeholder="Select Ended By" :options="['visitor', 'system']" class="w-100">
                            <template #option="slotProps">
                              <div class="flex items-center">
                                <div class="text-capitalize">{{ slotProps.option }}</div>
                              </div>
                            </template>
                            <template #value="slotProps">
                              <div v-if="slotProps?.value" class="flex items-center">
                                <div class="text-capitalize">{{ slotProps.value }}</div>
                              </div>
                              <span v-else>
                                {{ slotProps.placeholder }}
                              </span>
                            </template>
                          </Select>
                        </div>
                      </template>
                      <template v-else-if="['rating', '{{message.csat_score}}'].includes(condition.type)">
                        <div class="col-4 p-0 pe-2">
                          <Select v-if="condition.type" @change="changeConditionalRating(i, idx,  $event.value.option)"
                            filter name="" v-model="condition.operator" placeholder="Select Operator"
                            :options="['equals', 'notEquals', 'greaterThanOrEqual', 'lessThanOrEqual']" class="w-100">
                            <template #option="slotProps">
                              <div class="flex items-center">
                                <div class="text-capitalize">
                                  {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'greaterThanOrEqual' ? 'Greater than or is' : slotProps.option == 'lessThanOrEqual' ? 'Less than or is' : 'Is Not' }}
                                </div>
                              </div>
                            </template>
                            <template #value="slotProps">
                              <div v-if="slotProps?.value" class="flex items-center">
                                <div class="text-capitalize">
                                  {{ slotProps.value == 'equals' ? 'Is' : slotProps.value == 'greaterThanOrEqual' ? 'Greater than or is' : slotProps.option == 'lessThanOrEqual' ? 'Less than or is' : 'Is Not'  }}
                                </div>
                              </div>
                              <span v-else>
                                {{ slotProps.placeholder }}
                              </span>
                            </template>
                          </Select>
                        </div>
                        <div class="col-4 p-0">
                          <template v-if="condition.operator">
                            <input v-if="condition.operator !== 'equals'" type="text" placeholder="Type here..."
                              v-model="condition.value" class="form-control" />
                            <MultiSelect v-else v-model="condition.value" display="chip"
                              :options="['1','2','3','4','5']" filter :placeholder="'Select CSAT Scores'"
                              class="w-100 p-0" />
                          </template>
                        </div>
                      </template>
                      <template v-else>
                        <div class="col-4 px-1">
                          <Select v-if="condition.type" filter name="" v-model="condition.operator"
                            placeholder="Select Operator"
                            :options="condition.type == 'trigger' ? ['equals'] : ['equals', 'contains', 'notEquals']"
                            class="w-100">
                            <template #option="slotProps">
                              <div class="flex items-center">
                                <div class="text-capitalize">
                                  {{ slotProps.option == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' : 'Contains' }}
                                </div>
                              </div>
                            </template>
                            <template #value="slotProps">
                              <div v-if="slotProps?.value" class="flex items-center">
                                <div class="text-capitalize">
                                  {{ slotProps.value == 'equals' ? 'Is' : slotProps.option == 'notEquals' ? 'Is Not' : 'Contains' }}
                                </div>
                              </div>
                              <span v-else>
                                {{ slotProps.placeholder }}
                              </span>
                            </template>
                          </Select>
                        </div>
                        <div class="col-4 px-1">
                          <template v-if="condition.operator">
                            <Select v-if="condition.type == 'trigger'" filter name="" v-model="condition.value"
                              placeholder="Select Value" :options="getConditionalTriggerFilters.map((el) => el.name)"
                              class="w-100">
                              <template #option="slotProps">
                                <div class="flex items-center">
                                  <div class="text-capitalize">
                                    {{ getConditionalTriggerFilters.find((el) => el.name == slotProps.option)?.data.title ||  slotProps.option }}
                                  </div>
                                </div>
                              </template>
                              <template #value="slotProps">
                                <div v-if="slotProps?.value" class="flex items-center">
                                  <div class="text-capitalize">
                                    {{ getConditionalTriggerFilters.find((el) => el.name == slotProps.value)?.data.title || slotProps.value }}
                                  </div>
                                </div>
                                <span v-else>
                                  {{ slotProps.placeholder }}
                                </span>
                              </template>
                            </Select>
                            <InputText v-else placeholder="type here" type="text" class="w-100"
                              v-model="condition.value" />
                          </template>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="col-1-custom d-flex mb-2 justify-content-start px-0 align-items-center text-secondary">
                    <i class="fa-solid fa-trash cursor-pointer" v-if="idx !== 0" @click="deleteBranchData(i, idx)"></i>
                  </div>
                </div>
                <button class="btn mt-2" @click="addBranchCondition(i)"><i class="fa fa-add me-2"></i> Add
                  Condition</button>
              </div>
            </div>
            <button class="btn mt-2" @click="addBranch(i)"><i class="fa fa-add me-2"></i> Add Branch</button>
          </div>
        </template>

        <template v-if="selectedData?.name == 'delayAction'">
          <div class="form-group mb-3">
            <label for="webhookUrl" class="form-label">Wait Time</label>
            <div class="row">
              <div class="col-3">
                <label for="webhookUrl" class="form-label">Days</label>
                <InputText v-model="selectedData.days" type="number" id="delayAction" placeholder="Enter Time in seconds" class="w-100" />
              </div>
              <div class="col-3">
                <label for="webhookUrl" class="form-label">Hours</label>
                <Select filter v-model="selectedData.hours" 
                :options="Array.from({ length: 60 }).map((_, i) => i.toString())" placeholder="Select hours" class="w-100" />
              </div>
              <div class="col-3">
                <label for="webhookUrl" class="form-label">Mins</label>
                <Select filter v-model="selectedData.minutes" 
                :options="Array.from({ length: 60 }).map((_, i) => i.toString())" placeholder="Select minutes" class="w-100" />
              </div>
              <div class="col-3">
                <label for="webhookUrl" class="form-label">Seconds</label>
                <Select filter v-model="selectedData.seconds" 
                :options="Array.from({ length: 60 }).map((_, i) => i.toString())" placeholder="Select seconds" class="w-100" />
              </div>
            </div>
          </div>
        </template>

        <div class="mb-2 mt-1" v-if="isErrors && selectedData.filters.length">
          <span class="form-text text-danger">One or more filter(s) is/are empty</span>
        </div>
      </div>
    </div>
    <template v-else-if="selectedFlow === 'addNewTrigger'">
      <div class="trigger-header border-bottom d-flex">
        <div class="col-11">
          <h2 class="title">Automation Trigger</h2>
          <div class="description text-gray-500">Adds a automation trigger, and on execution, the contact gets added to
            the automation.</div>
        </div>
        <div class="text-indigo-200 col-1 text-end"
          @click="$emit('isOpen', !isOpen), $emit('showSubPanel', showSubPanel), $emit('resetPanel', '')">
          <svg class="cursor-pointer" style="width: 1.5rem; height: 1.5rem" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </div>
      </div>
      <div class="trigger-body">
        <div class="search-bar">
          <input v-model="searchTriggerQuery" type="text" placeholder="Search triggers"
            class="form-control border rounded" />
        </div>
        <!-- <div class="search-heading text-gray-500" v-if="searchTriggerQuery">Actions</div> -->
        <div class="mb-3" v-for="(flows, key) in filteredWorkflows" :key="key">
          <div class="workflow-group-title text-capitalize text-gray-500" v-if="flows.length">
            {{ key.split('_').join(' ') }}
          </div>
          <div class="border cursor-pointer workflows-labels" v-for="(data, index) in flows" :key="index"
            @click="selectWorkflow(data)">
            <span class="trigger-icon">
              <i class="fa" :class="data.icon"></i>
            </span>
            <span class="trigger-title">{{ data.label }}</span>
          </div>
        </div>
        <div class="not-found" v-if="Object.keys(filteredWorkflows).length == 0">There is no trigger with this filter
        </div>
      </div>
    </template>
    <template v-else-if="selectedFlow === 'addNewAction'">
      <div class="action-header d-flex border-bottom">
        <div class="col-11">
          <div class="title">Actions</div>
          <div class="description text-gray-500">Pick an action for this step</div>
        </div>
        <div class="text-indigo-200 col-1 text-end"
          @click="$emit('isOpen', !isOpen), $emit('showSubPanel', showSubPanel), $emit('resetPanel', '')">
          <svg class="cursor-pointer" style="width: 1.5rem; height: 1.5rem" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </div>
      </div>
      <div class="action-body">
        <div class="search-bar">
          <input v-model="searchActionQuery" type="text" placeholder="Search Action"
            class="form-control border rounded" />
        </div>
        <div class="search-heading text-gray-500" v-if="Object.keys(filteredActions).length && searchActionQuery">
          Actions</div>
        <div class="" v-for="(action, key) in filteredActions" :key="key">
          <div class="action-group-title text-gray-500" :class="key === 'ai' ? 'text-uppercase' : 'text-capitalize'"
            if="searchActionQuery == ''">{{ key.split('_').join(' ') }}</div>
          <div class="border cursor-pointer action-labels" v-for="(data, index) in action" :key="index"
            @click="selectWorkflow(data)">
            <span class="action-icon">
              <i class="fa" v-if="data.name == 'chatgpt'">
                <svg width="20" height="20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" stroke-width="1.5"
                  class="h-6 w-6" viewBox="-0.17090198558635983 0.482230148717937 41.14235318283891 40.0339509076386">
                  <text x="-9999" y="-9999">ChatGPT</text>
                  <path
                    d="M37.532 16.87a9.963 9.963 0 0 0-.856-8.184 10.078 10.078 0 0 0-10.855-4.835A9.964 9.964 0 0 0 18.306.5a10.079 10.079 0 0 0-9.614 6.977 9.967 9.967 0 0 0-6.664 4.834 10.08 10.08 0 0 0 1.24 11.817 9.965 9.965 0 0 0 .856 8.185 10.079 10.079 0 0 0 10.855 4.835 9.965 9.965 0 0 0 7.516 3.35 10.078 10.078 0 0 0 9.617-6.981 9.967 9.967 0 0 0 6.663-4.834 10.079 10.079 0 0 0-1.243-11.813zM22.498 37.886a7.474 7.474 0 0 1-4.799-1.735c.061-.033.168-.091.237-.134l7.964-4.6a1.294 1.294 0 0 0 .655-1.134V19.054l3.366 1.944a.12.12 0 0 1 .066.092v9.299a7.505 7.505 0 0 1-7.49 7.496zM6.392 31.006a7.471 7.471 0 0 1-.894-5.023c.06.036.162.099.237.141l7.964 4.6a1.297 1.297 0 0 0 1.308 0l9.724-5.614v3.888a.12.12 0 0 1-.048.103l-8.051 4.649a7.504 7.504 0 0 1-10.24-2.744zM4.297 13.62A7.469 7.469 0 0 1 8.2 10.333c0 .068-.004.19-.004.274v9.201a1.294 1.294 0 0 0 .654 1.132l9.723 5.614-3.366 1.944a.12.12 0 0 1-.114.01L7.04 23.856a7.504 7.504 0 0 1-2.743-10.237zm27.658 6.437l-9.724-5.615 3.367-1.943a.121.121 0 0 1 .113-.01l8.052 4.648a7.498 7.498 0 0 1-1.158 13.528v-9.476a1.293 1.293 0 0 0-.65-1.132zm3.35-5.043c-.059-.037-.162-.099-.236-.141l-7.965-4.6a1.298 1.298 0 0 0-1.308 0l-9.723 5.614v-3.888a.12.12 0 0 1 .048-.103l8.05-4.645a7.497 7.497 0 0 1 11.135 7.763zm-21.063 6.929l-3.367-1.944a.12.12 0 0 1-.065-.092v-9.299a7.497 7.497 0 0 1 12.293-5.756 6.94 6.94 0 0 0-.236.134l-7.965 4.6a1.294 1.294 0 0 0-.654 1.132l-.006 11.225zm1.829-3.943l4.33-2.501 4.332 2.5v5l-4.331 2.5-4.331-2.5V18z"
                    fill="currentColor" />
                </svg>
              </i>
              <i v-else-if="data.name == 'modalai'">
                <svg style="width: 16px; height: 16px; object-fit: cover;" class="my-2" viewBox="0 0 513 421"
                  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path id="SymbolLogo"
                    d="M486.367 5.66518C470.401 -2.0562 451.334 -0.0486403 437.383 10.7613L256.331 151.754L74.9685 10.298C60.8626 -0.357495 41.9513 -2.0562 26.1402 5.66518C10.4841 13.541 0.408447 29.6015 0.408447 47.2062V373.821C0.408447 391.58 10.4841 407.64 26.6052 415.516C33.1157 418.605 40.0912 420.149 46.9116 420.149C57.1423 420.149 67.218 416.752 75.5886 410.266L220.058 301.703C208.123 291.511 168.905 260.471 141.623 238.697L98.0651 274.06V149.746L175.88 210.9L254.781 275.605L255.091 275.296L437.228 410.266C451.179 421.23 470.246 423.238 486.212 415.516C502.178 407.795 512.408 391.58 512.408 373.821V47.2062C512.408 29.447 502.333 13.3866 486.367 5.66518ZM414.752 276.995L336.781 213.37L414.752 149.901V276.995Z"
                    fill="currentColor" />
                </svg>
              </i>
              <i v-else class="fa" :class="data.icon"></i>
            </span>
            <span class="action-title">{{ data.label }}</span>
          </div>
        </div>
        <div class="not-found" v-if="Object.keys(filteredActions).length == 0">No such actions found</div>
      </div>
    </template>
  </div>
  <template v-if="showSubPanel">
    <div class="bg-white p-3 position-absolute footer-btn d-flex border-top w-100 p-3"
      style="bottom: 0; left: 0; flex-wrap: wrap">
      <button v-if="isUpdate" class="btn btn-outline-danger" style="bottom: 0px"
        @click="deleteSelected()">Delete</button>
      <button @click="$emit('resetPanel', ''), (isFormTouched = false), (isErrors = false), (showError = false),
            (cursorPosition = null),
            (selectedCursorField = '')
        " class="btn btn-outline-white ms-auto me-2">
        Cancel
      </button>

      <button class="btn save-btn btn-success" @click="saveFlow">
        {{ isUpdate ? `Update ${selectedFlow === 'addNewTrigger' ? 'Trigger' : 'Action'}` : `Save ${selectedFlow === 'addNewTrigger' ? 'Trigger' : 'Action'}` }}
      </button>
    </div>
  </template>
</template>
<script>
  import Api from '../Api';
  import setTooltip from '@/assets/js/tooltip.js';
  import moment from 'moment';
  import PlaceHolders from './PlaceHolders';
  import placeholdersjson from '@/assets/js/placeholdersjson'
  export default {
    name: 'CondtionForm',
    props: ['flowTitle', 'selectedNode', 'showSubPanel', 'selectedFlow', 'isOpen', 'isUpdate', 'ticket_statuses', 'nodes','formFields'],
    emits: [
      'flowTitle',
      'addFlow',
      'isOpen',
      'selectWorkflow',
      'showSubPanel',
      'resetPanel',
      'deleteFlow',
      'isErrors',
    ],
    components: {
      PlaceHolders,
    },
    data() {
      return {
        history: [], // Stores the history of notes
        currentIndex: -1, // Current position in the history

        selectedCursorField: '',
        cursorPosition: null,
        selectedTrigger: {},
        selectedData: {},
        singleAssignedUsers: {},
        isAttachmentUploading: false,
        configOptions: {
          image: false,
          addbar: false,

          buttons: {
            topbar: [''],
          },
          editor: {
            minHeight: '200px',
            focus: true,
          },
        },

        statusFilter: [
          { label: 'Newest', value: 'newest' },
          { label: 'Oldest ', value: 'oldest ' },
          { label: 'Waiting Longest', value: 'waiting_longest' },
          { label: 'Priority First', value: 'priority_first' },
          { label: 'None', value: '' },
        ],

        daysOfWeek: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],

        triggerWorkflowsOptions: [
          { label: 'Chat', items: [{ label: 'When Chat Ends', name: 'onChatEnded', type: 'input', icon: 'fas fa-comment-slash' }] },
          { label: 'Message', items: [{ label: 'New Message', name: 'onNewMessage', type: 'input', icon: 'fa fa-messages' }] },
          { label: 'Ticket', items: [{ label: 'Ticket Created', name: 'ticketCreated', type: 'input', icon: 'fa fa-ticket' }] },
          { label: 'CSAT', items: [{ label: 'CSAT Submitted', name: 'conversationRated', type: 'input', icon: 'fa fa-star-half-o' }] },
        ],
        ticketFilters: [
          {
            label: 'Recipients',
            items: [{ value: '', type: 'from', operator: '', label:'From' }],
          },
          {
            label: 'Content',
            items: [
              { value: '', type: 'subject', operator: '',  label:'Subject' },
              { value: '', type: 'body', operator: '',  label:'Body' },
            ],
          },
          {
            label: 'Time',
            items: [
              { type: 'isReceivedOnDate', value: '', operator: '',  label:'Is Received On Date' },
              { type: 'receivedDay', value: '', operator: '', label:'Received Day' },
              { type: 'timeWithinRange', value: '', startTime: '', endTime: '', label:'Time Within Range (UTC)' },
            ],
          },
        ],
        onNewMessageFilters: [
          {
            label: 'Sender',
            items: [
              { value: '', type: 'senderName', operator: '',  label:'Name'  },
              { value: '', type: 'senderEmail', operator: '',  label:'Email'  },
            ],
          },
          {
            label: 'Channel',
            items: [
              { value: '', type: 'channel', operator: '', label:'channel' },
              { value: '', type: 'channelType', operator: '', label:'channel Type' },
            ],
          },
          {
            label: 'Content',
            items: [
              { value: '', type: 'subject', operator: '', label:'Subject' },
              { value: '', type: 'body', operator: '', label:'Body' },
            ],
          },
          {
            label: 'Team Members',
            items: [{ value: [], type: 'isAssignedTo', operator: '', field: 'endedBy', label:'is Assigned To'}],
          },
          {
            label: 'Time',
            items: [
              { type: 'isReceivedOnDate', value: '', operator: '',  label:'Is Received On Date' },
              { type: 'receivedDay', value: '', operator: '', label:'Received Day' },
              { type: 'timeWithinRange', value: '', startTime: '', endTime: '', label:'Time Within Range (UTC)' },
            ],
          },
        ],
        onChatEndedFilters: [
          {
            label: 'Chat',
            items: [{ value: '', type: 'endedBy', operator: '', label:'Ended By' }],
          },
        ],
        conversationRatedFilters: [
          {
            label: 'CSAT',
            items: [{ value: '', type: 'rating', operator: '', label:'Rating' }],
          },
        ],
        conditionFilters: [
          {
            label: 'Recipients',
            items: [{ value: '', type: 'from', operator: '', label:'From' }],
          },
          {
            label: 'Sender',
            items: [
              { value: '', type: 'senderName', operator: '', label:'Name' },
              { value: '', type: 'senderEmail', operator: '', label:'Email' },
            ],
          },
          // {
          //   label: 'CSAT',
          //   items: [{ value: '', type: 'rating', operator: '', label:'Rating' }],
          // },
          // {
          //   label: 'New Message',
          //   items: [
          //     { value: '', type: 'channel', operator: '', label:'channel' },
          //     { value: '', type: 'channelType', operator: '', label:'channel Type' },
          //   ],
          // },
          // {
          //   label: 'Chat',
          //   items: [{ value: '', type: 'endedBy', operator: '', label:'Ended By' }],
          // },
          {
            label: 'Content',
            items: [
              { value: '', type: 'subject', operator: '', label:'Subject' },
              { value: '', type: 'body', operator: '', label:'Body' },
            ],
          },
          {
            label: 'Team Members',
            items: [{ value: [], type: 'isAssignedTo', operator: '', field: 'endedBy', label:'Is Assigned To' }],
          },
          {
            label: 'Time',
            items: [
            { type: 'isReceivedOnDate', value: '', operator: '',  label:'Is Received On Date' },
              { type: 'receivedDay', value: '', operator: '', label:'Received Day' },
              { type: 'timeWithinRange', value: '', startTime: '', endTime: '', label:'Time Within Range (UTC)' },
            ],
          },
        ],

        triggerWorkflows: {
          chat: [{ label: 'When Chat Ends', name: 'onChatEnded', type: 'input', filters: [], showAddFilterBtn: true, hasFilters: true, icon: 'fas fa-comment-slash' }],
          message: [
            {
              label: 'New Message',
              name: 'onNewMessage',
              type: 'input',
              filters: [],
              showAddFilterBtn: true,
              hasFilters: true,
              icon: 'fa fa-messages',
            },
          ],
          ticket: [{ label: 'Ticket Created', name: 'ticketCreated', type: 'input', filters: [], showAddFilterBtn: true, hasFilters: true, icon: 'fa fa-ticket' }],
          CSAT: [{ label: 'CSAT Submitted', name: 'conversationRated', type: 'input', filters: [], showAddFilterBtn: true, hasFilters: true, icon: 'fa fa-star-half-o' }],
        },
        actions: {
          ai: [
            { label: 'OpenAI', name: 'chatgpt', type: 'input', filters: [], hasFilters: false, model: 'gpt-4o', prompt: '', temperature: '', max_tokens: '', top_p: '', lookupSource:'WEB_API' },
            { label: 'Anthropic (Claude)', name: 'claude', type: 'input', filters: [], hasFilters: false, model: 'claude-3-haiku-20240307', prompt: '', temperature: '', max_tokens: '', top_p: '', top_k:'', icon:'fa-solid fa-microchip-ai' },
            { label: 'Google AI Studio (Gemini)', name: 'gemini', type: 'input', filters: [], hasFilters: false, model: 'gemini-1.5-flash', prompt: '', stopSequences:[], maxOutputTokens:'', temperature:'', icon:'fa-brands fa-google' },
            { label: 'Meta (Llama 3.0)', name: 'llama', type: 'input', filters: [], hasFilters: false, model: 'llama3.1-405b', prompt: '', temperature: '', max_tokens: '', top_p: '', icon:'fa-brands fa-meta' },
            // { label: 'Modal Ai', name: 'modalai', type: 'input', filters: [], hasFilters: false, prompt: '', icon:'fa-brands fa-google' },
          ],
          assignment: [
            {
              label: 'Assign To',
              name: 'assignTicket',
              type: 'input',
              filters: [],
              assignedUsers: [],
              roundRobin: false,
              showAddFilterBtn: false,
              hasFilters: false,
              icon: 'fa fa-solid fa-user-check',
            },
          ],
          internal_tools: [
            {
              label: 'If / Else',
              name: 'condition',
              type: 'input',
              filters: [],
              showAddFilterBtn: false,
              hasFilters: false,
              branches: [
                {
                  branch_name: '',
                  id: `branch-${moment().valueOf()}`,
                  condition: {
                    type: 'AND',
                    conditions: [
                      {
                        type: '',
                        operator: '',
                        value: '',
                      },
                    ],
                  },
                },

                {
                  branch_name: 'None',
                  id: `branch-none-${moment().valueOf()}`,
                  condition: {
                    type: 'NONE',
                  }
                },
              ],
              icon: 'fas fa-brackets-curly',
            },
            {label: 'Wait Time', name: 'delayAction', type: 'input', filters: [], showAddFilterBtn: false, hasFilters: false, days:0, hours:'0', minutes:'0', seconds:'0', icon: 'fa fa-history'}
          ],
          messaging: [
            { label: 'Auto Reply', name: 'autoReply', subject: '', message: '', type: 'input', replyType: '', agent_ref:'', filters: [], showAddFilterBtn: false, hasFilters: false, icon: 'fa-solid fa-reply' },
            { label: 'Forward an Email', name: 'forwardEmail', type: 'input', email: '', filters: [], showAddFilterBtn: false, hasFilters: false, icon: 'fa-solid fa-share' },
            { label: 'Redirect Email', name: 'redirectEmail', type: 'input', email: '', filters: [], showAddFilterBtn: false, hasFilters: false, icon: 'fa-solid fa-share' },
          ],
          note: [{ label: 'Add a Note', name: 'createNote', note: '', type: 'input', filters: [], assignedUsers: [], showAddFilterBtn: false, hasFilters: false, icon: 'fa fa-solid fa-notes' }],
          tags: [
            { label: 'Add Tags', name: 'addTag', type: 'input', filters: [], selectedTags: [], showAddFilterBtn: false, hasFilters: false, icon: 'fa fa-tag' },
            { label: 'Remove Tags', name: 'removeTag', type: 'input', filters: [], selectedTags: [], showAddFilterBtn: false, hasFilters: false, icon: 'fa-regular fa-tag' },
            { label: 'Remove All Tags', name: 'removeAllTags', type: 'input', filters: [], showAddFilterBtn: false, hasFilters: false, icon: 'fa-regular fa-tags' },
          ],
          ticket_status: [{ label: 'Ticket Status Change', name: 'updateTicketStatus', type: 'input', ticket_status_id: '', filters: [], hasFilters: false, icon: 'fa fa-tag' }],
          webhook: [
            { label: 'Webhook', name: 'webhook', type: 'input', method: 'post', api_url: '', customData: [], headers: [], filters: [], hasFilters: false, icon: 'fa fa-wifi' },
            // { label: 'Send to AI Response', name: 'fetchConversation', type: 'input', api_url: '', headers: [], sendToModalSupport: false, filters: [], hasFilters: true, icon: 'fas fa-comment-alt' },
          ],
        },
        searchActionQuery: '',
        searchTriggerQuery: '',
        support_lib_id: this.$route.query.support_lib_id,
        parent_lib_id: this.$route.query.parent_lib_id,
        user_id: this.$route.query.user_id,
        isAssignmentAgentListLoading: false,
        isLoadingTags: false,
        assignmentAgentList: [],
        tags: [],
        isErrors: false,
        showError: false,
        isFormTouched: false,
        selectTicketFilter: '',
        agentList:[]
      };
    },
    updated() {
      this.initializeTooltips();
    },
    methods: {
      getToggleOptions(event) {
        if(event?.trueValue !== undefined && event?.falseValue !== undefined) {
          return [event?.trueValue.toString(), event.falseValue.toString()]
        } else {
          return ['0', '1', 'true', 'false']
        }
      },
      getRadioOptions(event) {
        return event?.radioValues
      },
      checkConditionalFilterBuilder(event) {
        for (const key in this.getConditionalFilters) {
            const element = this.getConditionalFilters[key];
            if(element.items.find((el) => el.type == event)) {
              return element.items.find((el) => el.type == event).elementBuilder
            } 
        }
        return false
      },
      changeConditionalRating(i, idx, option) {
        if (
          this.selectedData &&
          this.selectedData.branches &&
          this.selectedData.branches[i] &&
          this.selectedData.branches[i].condition &&
          this.selectedData.branches[i].condition.conditions &&
          this.selectedData.branches[i].condition.conditions[idx]
        ) {
          this.selectedData.branches[i].condition.conditions[idx].value =
            option === "equals" ? [] : "";
        }
      },
      initializeTooltips() {
        this.$nextTick(() => {
          setTooltip(this.$store.state.bootstrap);
        });
      },
      deleteBranchCondition(event) {
        this.selectedData.branches[event.i].condition.conditions.splice(
          event.idx,
          1
        );
      },

      addBranch() {
        let payload = {
          branch_name: "",
          id: `branch-${moment().valueOf()}`,
          condition: {
            type: "AND",
            conditions: [
              {
                type: "",
                operator: "",
                value: "",
              },
            ],
          },
        };
        this.selectedData.branches.splice(this.selectedData.branches.length - 1, 0, payload);
      },

      removeBranch(i) {
        this.selectedData.branches.splice(i, 1);
      },
      addBranchCondition(i) {
        let payload = {
          type: "",
          operator: "",
          value: "",
        };
        this.selectedData.branches[i].condition.conditions.push(payload);
      },
       removeFilter(event) {
        this.selectedData.filters.splice(event, 1)
       },
       removeWebhookCustomData(event) {
        this.selectedData.customData.splice(event, 1)
      },
      removeCustomHeaders(event) {
        this.selectedData.headers.splice(event, 1)
      },
      appendFetchConvesationHeaders(e) {
        this.selectedData.headers = [...this.selectedData.headers, e];
      },
      appendWebhookHeaders(e) {
        this.selectedData.headers = [...this.selectedData.headers, e];
      },
      appendWebhookCustomData(e) {
        this.selectedData.customData = [...this.selectedData.customData, e]
      },
      deleteSelected() {
        let checkIsAiSelected = ['chatgpt', 'claude', 'gemini', 'llama', 'modalai'].includes(this.selectedData.name)
        let text = "The changes will only take effect when you save this workflow"
        if(checkIsAiSelected) {
          text += `and will effect its corresponding placeholders`
        } 
        text += '.'
        this.$swal({
          iconHtml: `
                <i class="fa fa-trash text-danger custom-popup-icon"></i> 
                <i id="custom-close-btn" class="custom-close-btn fa fa-times text-sm cursor-pointer" aria-label="Close"> </i>
            `, // Adding an icon similar to the trash bin
          title: "Are you sure you want to delete this step?",
          text: text,
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
          customClass: {
            popup: "custom-swal-popup", // Custom class for the modal
            title: "custom-swal-title px-0", // Custom class for the title
            htmlContainer: "custom-swal-text px-0 m-0", // Custom class for text content
            actions: "custom-swal-actions",
            cancelButton: "btn btn-outline-secondary border", // Custom class for cancel button
            confirmButton: "btn btn-danger", // Custom class for confirm button
          },
          buttonsStyling: false,
          didOpen: () => {
            // Add a click event listener to the custom close button to close the modal
            document
              .getElementById("custom-close-btn")
              .addEventListener("click", () => {
                this.$swal.close(); // Close the SweetAlert modal
              });
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$emit('deleteFlow', '')
          }
        })
      },
      deleteBranchData(i ,idx) {
        this.$swal({
          iconHtml: `
                <i class="fa fa-trash text-danger custom-popup-icon"></i> 
                <i id="custom-close-btn" class="custom-close-btn fa fa-times text-sm cursor-pointer" aria-label="Close"> </i>
            `, // Adding an icon similar to the trash bin
          title: "Are you sure you want to delete this step?",
          text: "The changes will only take effect when you save this workflow.",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
          customClass: {
            popup: "custom-swal-popup", // Custom class for the modal
            title: "custom-swal-title px-0", // Custom class for the title
            htmlContainer: "custom-swal-text px-0 m-0", // Custom class for text content
            actions: "custom-swal-actions",
            cancelButton: "btn btn-outline-secondary border", // Custom class for cancel button
            confirmButton: "btn btn-danger", // Custom class for confirm button
          },
          buttonsStyling: false,
          didOpen: () => {
            // Add a click event listener to the custom close button to close the modal
            document
              .getElementById("custom-close-btn")
              .addEventListener("click", () => {
                this.$swal.close(); // Close the SweetAlert modal
              });
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.deleteBranchCondition({ i, idx })
          }
        })
      },
      checkFilterValidation() {
       let check = !this.selectedData?.filters?.some((el) => (el?.type == "timeWithinRange" ? (el.startTime == '' || el.endTime == '') : ( el?.value == '')))
       return check
      }, 
      getConditionFilterLabel(props) {
        const label = this.getConditionalFilters.reduce((acc, el) => {
          const item = el.items.find((ele) => ele.type === props.value);
          return item ? item.label : acc;
        }, null);
        return label
      },
      onChangeStopSequences() {
        if (this.selectedData.stopSequences.length > 5) {
        this.selectedData.stopSequences.pop(); 
        this.$store.state.showToast = {
          icon: 'fa fa-exclamation-triangle',
          title: `Attention`,
          description: `You can only enter up to 5 tags`,
          time: moment().valueOf(),
        };
        // this.$toast.add({severity: 'warn', summary: 'Limit Exceeded', detail: 'You can only select up to 5 tags.', life: 3000});
      }
      },
      showFilterAlert() {
        this.$store.state.showToast = {
          icon: 'fa fa-exclamation-triangle',
          title: `Attention`,
          description: `Please complete your previous filter first`,
          time: moment().valueOf(),
        };
      },
      filterAdditionalFilters(item) {
        const hasMatchingType = this.selectedData.filters.some((filter) => filter.type === item.type);
        return !hasMatchingType;
      },
      checkSingleAssignedUser(event) {
        // console.log('checkSingleAssignedUser ', event)
        this.selectedData.assignedUsers = [event.value];
      },
      wordCount() {
        if (!this.selectedData?.note) return 0;
        return this.selectedData.note.trim().split(/\s+/).filter(Boolean).length;
      },
      // Count the number of characters in the note
      characterCount() {
        return this.selectedData?.note ? this.selectedData.note.length : 0;
      },
      handleInput() {
        if (this.currentIndex < this.history.length - 1) {
          this.history = this.history.slice(0, this.currentIndex + 1);
        }
        this.history.push(this.selectedData.note);
        this.currentIndex++;
      },
      // Undo the last change
      undo(id) {
        if (this.canUndo) {
          this.currentIndex--;
          this.selectedData[id] = this.history[this.currentIndex];
        }
      },
      // Redo the undone change
      redo(id) {
        if (this.canRedo) {
          this.currentIndex++;
          this.selectedData[id] = this.history[this.currentIndex];
        }
      },
      updateCursorPosition(event, ref) {
        // Update cursor position when user types
        this.cursorPosition = event.target.selectionStart;
        // console.log(' this.cursorPosition ',  this.cursorPosition)
        this.selectedCursorField = ref;
      },
      appendPlaceholderData(placeholder, id) {
        // Check if cursor position is valid and if the field matches the selected field ID
        if (this.cursorPosition !== null && this.selectedCursorField === id) {
          const textarea = document.getElementById(id);
          if (!textarea) return; // Ensure textarea is available

          const value = this.selectedData[id];

          // Insert placeholder text at the current cursor position
          const beforeCursor = value.slice(0, this.cursorPosition);
          const afterCursor = value.slice(this.cursorPosition);
          this.selectedData[id] = beforeCursor + placeholder + afterCursor;

          // Update cursor position after inserting the placeholder
          this.$nextTick(() => {
            this.cursorPosition += placeholder.length; // Update cursor position
            textarea.setSelectionRange(this.cursorPosition, this.cursorPosition); // Set cursor position
            textarea.focus(); // Focus on the textarea first to ensure selection works
          });
        } else {
          // If cursor position is not set, append placeholder at the end
          this.selectedData[id] = this.selectedData[id] + ' ' + placeholder;
        }
      },

      selectTrigger(e) {
        let findWorkflowByName = (name) => {
          for (let key in this.triggerWorkflows) {
            let workflow = this.triggerWorkflows[key].find((item) => item.name === name);
            if (workflow) {
              return workflow;
            }
          }
          return null; // return null if not found
        };
        let payload = findWorkflowByName(e.value.name);
        payload = {
          filters: [],
          hasFilters: true,
          id: this.selectedData.id,
          label: payload.label,
          name: payload.name,
          position: this.selectedData.position,
          data: {
            add: false,
            branchId: null,
            icon: payload.icon,
            name: payload.name,
            title: payload.label,
            type: "addNewTrigger",
          },
          showAddFilterBtn: payload.showAddFilterBtn,
          type: 'custom',
        };

        this.selectWorkflow(payload);
      },
      selectWorkflow(payload) {
        this.selectedData = { ...payload };
        this.$emit('selectWorkflow', payload);
      },
      sortAssignmentList(e) {
        // console.log('sortAssignmentList :- ', e.target.checked)
        if (!e.target.checked) {
          if (this.selectedData.assignedUsers.length) {
            this.selectedData.assignedUsers = [this.selectedData.assignedUsers[0]];
            this.singleAssignedUsers = this.selectedData.assignedUsers[0];
          } else {
            this.selectedData.assignedUsers = [];
            this.singleAssignedUsers = {};
          }
        } else {
          this.selectedData.assignedUsers = [];
        }
      },
      formatLabel(str) {
        if (str) {
          return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, function (str) {
            return str;
          });
        }
      },
      appendCustomFilter() {
        let payload = { type: '', value: '', operator: '' }
        // this.$emit('appendFilter', payload);
        this.selectedData.filters.push(payload)
      },
      appendFilter(event = undefined) {
        console.log('event ', event);
        console.log('this.selectedData?.name ', this.selectedData?.name);
        if (['ticketCreated', 'onNewMessage', 'onChatEnded', 'conversationRate'].includes(this.selectedData?.name)) {
          if (['isAssignedTo', 'isUnassigned'].includes(event)) {
            this.$emit('appendFilter', { type: event, field: 'endedBy', operator: '', value: [] });
          } else if (event == 'timeWithinRange') {
            this.$emit('appendFilter', { type: event, startTime: '', endTime: '' });
          } else if (['from', 'to', 'senderEmail', 'senderName', 'endedBy', 'body', 'subject', 'isReceivedOnDate', 'channel', 'channelType', 'receivedDay', 'rating'].includes(event)) {
            this.$emit('appendFilter', { type: event, value: '', operator: '' });
          } else {
            this.$emit('appendFilter', { type: event, value: '' });
          }
        }
        this.scrollToBottom();
      },
      checkFilters(e) {
        let check = false;
        if (['ticketCreated', 'onNewMessage', 'onChatEnded', 'conversationRated'].includes(this.selectedData?.name)) {
          if (['isAssignedTo', 'isUnassigned'].includes(e.type)) {
            if(e.value == []) {
              check = true;
            }
          } else if (e.type == 'timeWithinRange') {
            if((e.startTime == '' || e.endTime == '')) {
              check = true;
            }
          } else if (['from', 'to', 'senderEmail', 'senderName', 'body', 'subject', 'isAssignedTo', 'endedBy'].includes(e.type)) {
            if((e.value == '' || e.operator == '')) {
              check = true;
            }
          } else if(['rating'].includes(e.type)) {
            if(e.operator == '') {
              check = true;
            } else {
              if(e.operator == 'equals') {
                if(e.value.length == 0) {
                  check = true;
                }
              } else if(e.value.trim() == '') {
                check = true;
              }
            } 
          } else if (e.value == '') {
            check = true;
          }
        }
        return check;
      },

      getAvailableAssignmentAgentList() {
        this.isAssignmentAgentListLoading = true;
        let params = { support_lib_id: this.support_lib_id, rt: 'assignment' };
        if (this.parent_lib_id && this.parent_lib_id !== this.support_lib_id) {
          params = { ...params, parent_lib_id: this.parent_lib_id };
        }
        // console.log('params', params)
        Api.getAgentList(params, this.$route.query.token)
          .then((res) => {
            // console.log('getAgentList :' , res?.data)
            if (res?.data?.agents_list) {
              this.assignmentAgentList = res?.data?.agents_list;
              // console.log('this.assignmentAgentList ', this.assignmentAgentList)
            }
          })
          .catch((err) => {
            console.log('err :- ', err);
          })
          .finally(() => {
            this.isAssignmentAgentListLoading = false;
          });
      },
      getAgentList() {
        let params = { support_lib_id: this.support_lib_id, rt:'assignment' };
        // console.log('params', params)
        Api.getAgentList(params, this.$route.query.token)
          .then((res) => {
            // console.log('getAgentList :' , res?.data)
            if (res?.data?.agents_list) {
              this.agentList = res?.data?.agents_list;
              // console.log('this.assignmentAgentList ', this.assignmentAgentList)
            }
          })
          .catch((err) => {
            console.log('err :- ', err);
          })
      },
      fetchTags(tagId = undefined) {
        this.isLoadingTags = true;
        Api.fetchTags({ support_lib_id: this.$route.query.support_lib_id, show_all:1 }, this.$route.query.token)
          .then((res) => {
            if (res?.data?.all_tags) {
              this.tags = res?.data?.all_tags;
              if (tagId) {
                let findTag = this.tags.find((el) => el.id == tagId)
                if(findTag) {
                  this.selectedData.selectedTags.push(findTag);
                }
              }
            }
          })
          .catch((err) => {
            console.log('Error while fetcing tags ', err);
          })
          .finally(() => {
            this.isLoadingTags = false;
          });
      },
      addNewTag() {
        this.isLoadingTags = true;
        let element = document.getElementsByClassName('p-multiselect-filter')[0];
        if (element.value) {
          let tag = element.value;
          this.isLoadingTags = true;
          let payload = {
            support_lib_id: this.support_lib_id,
            tag: tag,
            added_by: this.user_id,
          };
          let responseTagId = ''
          Api.addTags(payload, this.$route.query.token)
            .then((res) => {
              if(res?.data?.tag_id) {
                responseTagId = res?.data?.tag_id
              }
            })
            .catch((err) => {
              console.log('Error while fetcing tags ', err);
            })
            .finally(() => {
              this.fetchTags(responseTagId);
            });
        } else {
          this.isLoadingTags = false;
        }
      },

      scrollToBottom() {
        setTimeout(() => {
          const automationActionForm = this.$refs.automationActionForm;
          automationActionForm.scrollTop = automationActionForm.scrollHeight;
        }, 100);
      },

      checkFilter() {
        let isDisabled = false;
        if (this.flowTitle == '') {
          isDisabled = true;
        }
        if (
          this.selectedData?.name == 'condition' &&
          this.selectedData.branches.some((el) => el?.condition?.type !== 'NONE' && (el?.branch_name == '' || el?.condition?.conditions.some((ele) => ele.value == '' || ele.type == '' || ele.operator == '')))
        ) {
          isDisabled = true;
        }
        if (this.selectedData?.name == 'updateTicketStatus' && this.selectedData?.ticket_status_id == '') {
          isDisabled = true;
        }
        if (this.selectedData?.hasFilters && this.selectedData.filters.length && this.selectedData.filters?.some(this.checkFilters)) {
          isDisabled = true;
        }
        if (
          this.selectedData?.name == 'webhook' &&
          (this.selectedData?.api_url == '' ||
            this.selectedData?.method == '' ||
            (this.selectedData?.customData?.length && this.selectedData?.customData?.some((el) => el?.key == '' || el.value == '')) ||
            (this.selectedData?.headers?.length && this.selectedData?.headers?.some((el) => el?.key == '' || el.value == '')))
        ) {
          isDisabled = true;
        }
        if (this.selectedData?.name == 'fetchConversation') {
          if (
            (!this.selectedData?.sendToModalSupport && this.selectedData?.api_url == '') ||
            (this.selectedData?.headers?.length && this.selectedData?.headers?.some((el) => el?.key == '' || el.value == ''))
          ) {
            isDisabled = true;
          }
        }
        if (this.selectedData?.name == 'assignTicket' && this.selectedData?.assignedUsers.length == 0) {
          isDisabled = true;
        }
        if (this.selectedData?.name == 'createNote' && this.selectedData.note.trim().length == 0) {
          isDisabled = true;
        }
        if (['addTag', 'removeTag'].includes(this.selectedData?.name) && this.selectedData?.selectedTags?.length == 0) {
          isDisabled = true;
        }
        if (this.selectedData.name == 'autoReply') {
          if (this.selectedData?.replyType == '' || this.selectedData?.message.trim() == '') {
            isDisabled = true;
          } else if (this.selectedData?.replyType == 'TICKET' && (this.selectedData?.subject.trim() == '' || this.selectedData?.message.trim() == '')) {
            isDisabled = true;
          }
        }
        if (['redirectEmail', 'forwardEmail'].includes(this.selectedData.name) && this.selectedData?.email.trim() == '') {
          isDisabled = true;
        }
        if (['chatgpt', 'claude', 'llama', 'gemini'].includes(this.selectedData?.name) &&
          (this.selectedData?.model == '' || this.selectedData?.prompt.trim() == '' )
        ) {
          isDisabled = true;
        }
        return isDisabled;
      },
      saveFlow() {
        this.cursorPosition = null;
        this.selectedCursorField = '';
        let isDisabled = this.checkFilter();
        this.$emit('isErrors', isDisabled);
        if (!isDisabled && this.flowTitle.trim().length <= 50) {
          this.$emit('addFlow', this.selectedData);
          this.isErrors = false;
          this.showError = false;
          this.isFormTouched = false;
        } else {
          // console.log('going in else');
          this.isErrors = true;
          this.showError = true;
          this.isFormTouched = true;
        }
      },
    },
    mounted() {
      this.getAvailableAssignmentAgentList();
      this.getAgentList()
      this.fetchTags();
    },
    computed: {
      getConditionalTriggerFilters() {
        let triggers = []; 
        this.nodes.filter((el) => el.data.type == 'addNewTrigger' && !el.data.add).forEach((el) => {
          if(!triggers.find((elem) => elem.name == el.name)) {
            triggers.push(el)
          }
        });
        console.log('triggers :- ', triggers)
        return triggers
      },
      getConditionalFilters() {
        let payload = {
          label: 'Workflow Trigger',
          items: [{value:'', type:'trigger', operator:'', label:'Trigger'} ],
        }

        let placeHolders = {...placeholdersjson}

        let placeHolder = []

        let index = this.nodes.findIndex((el) => el.id == this.selectedData.id)
        if(index == -1) {
          index = this.nodes.findIndex((el) => el.id == this.$store.state.selectedNodeId)
        }
        let aiNodes = this.nodes.filter((el,i ) => i < index && !el.data.add && el.data.type == "addNewAction" && ['llama','claude', 'gemini', 'chatgpt'].includes(el.name))
        if(this.nodes[index]?.data?.branchId) {
          let branchesToIncludes = [this.nodes[index]?.data?.branchId]
          const findBranchesToIncludes = (branchId) => {
            let isBranchLabelNode = this.nodes?.filter((el) => el.data?.branchId == branchId).find((el) => el.data.isBranchLabelNode)
            if(isBranchLabelNode) {
              let findParent = this.nodes.find((el) => el.id == isBranchLabelNode.data.parentId)
              if(findParent?.data?.branchId) {
                findBranchesToIncludes(findParent?.data?.branchId)
                branchesToIncludes.push(findParent?.data?.branchId)
              }
            }
          }
          findBranchesToIncludes(this.nodes[index]?.data?.branchId)
          aiNodes = aiNodes?.filter((el) =>  el.data?.branchId == null || branchesToIncludes.includes(el.data?.branchId))
        }
        if(aiNodes?.length) {
          placeHolders['AI'] = []
        } else {
          delete placeHolders['AI'];
        }
        // console.log('aiNodes ', aiNodes)
        aiNodes.forEach(element => {
          if(element?.name == 'chatgpt') {
            const label = element?.data?.serialNo ? `#${element?.data?.serialNo} OpenAI Response` :  'OpenAI Response';
            const value = element?.data?.serialNo ? `{{ai.openai_response.${element?.data?.serialNo}}}` :  '{{ai.openai_response}}';
            placeHolders['AI'].push({ label:label, value: value, category: 'Ai', name:'chatgpt' },)
          }
          if(element?.name == 'gemini') {
            const label = element?.data?.serialNo ? `#${element?.data?.serialNo} Gemini Response` :  'Gemini Response';
            const value = element?.data?.serialNo ? `{{ai.gemini_response.${element?.data?.serialNo}}}` :  '{{ai.gemini_response}}';
            placeHolders['AI'].push({ label: label, value: value, category: 'Ai', name:'gemini' },)
          }
          if(element?.name == 'claude') {
            const label = element?.data?.serialNo ? `#${element?.data?.serialNo} Claude Response` :  'Claude Response';
            const value = element?.data?.serialNo ? `{{ai.claude_response.${element?.data?.serialNo}}}` :  '{{ai.claude_response}}';
            placeHolders['AI'].push( { label: label, value: value, category: 'Ai', name:'claude' },)
          }
          if(element?.name == 'llama') {
            const label = element?.data?.serialNo ? `#${element?.data?.serialNo} Llama Response` :  'Llama Response';
            const value = element?.data?.serialNo ? `{{ai.llama_response.${element?.data?.serialNo}}}` :  '{{ai.llama_response}}';
            placeHolders['AI'].push({ label: label, value:value, category: 'Ai', name:'llama' },)
          }
        });
        if(placeHolders['AI']) {
          placeHolders['AI'] = placeHolders['AI'].sort((a, b) => a.label.localeCompare(b.label))
        }

        for (const key in placeHolders) {
            const element = placeHolders[key];
            let items = []
            element.forEach((el) => {
              items.push({value:'', type:el.value, operator:'', label:el?.label})
            })
            placeHolder.push({label:key, items:items})
        }

        let cloned =  [payload, ...this.conditionFilters, ...placeHolder]
        cloned = cloned.sort((a, b) => a.label.localeCompare(b.label))
        // console.log('cloned ', cloned)

        // cloned = Object.keys(cloned).sort().reduce(
        //   (obj, key) => { 
        //     obj[key] = cloned[key]; 
        //     return obj;
        //   }, 
        //   {}
        // );

        if(this.formFields?.schema && Object.keys(this.formFields?.schema)?.length) {
          let payload = {
            label: 'Form Fields',
            items: [],
          }

          // let elem = []
          for (const key in this.formFields?.schema) {
            const element = this.formFields?.schema[key];
            if(!element?.submits && !element?.resets) {
              // if(element?.type == 'radio') {
              //   let index = payload.items.findIndex((el) => el?.elementBuilder?.radioName == element?.radioName)
              //   if(index !== -1) {
              //     payload.items[index]?.elementBuilder.radioValues.push({label:element?.text || '',value:element?.radioValue || ''})
              //   } else {
              //     payload.items.push({value:'', type:element?.name, operator:'', label:element.label ? element.label : element.name, elementBuilder:{...element, radioValues:[{label:element?.text || '',value:element?.radioValue || ''}]} } )
              //   }
              // } else {
                payload.items.push({value:'', type:element?.name, operator:'', label:element.label ? element.label : element.name, elementBuilder:{...element} } )
              // }
            }
          }
          // console.log('payload ', payload)
          cloned = [...cloned, payload]
          cloned = cloned.sort((a, b) => a.label.localeCompare(b.label))
        }


        return cloned
      },
      getFilteredOptions() {
        let options = [];

        if (this.selectedData?.name === 'ticketCreated') {
          options = this.ticketFilters;
        } else if (this.selectedData?.name === 'onChatEnded') {
          options = this.onChatEndedFilters;
        } else if (this.selectedData?.name === 'onNewMessage') {
          options = this.onNewMessageFilters;
        } else {
          options = this.conversationRatedFilters
        }

        let placeHolders = {...placeholdersjson}
        let placeHolder  = []
        for (const key in placeHolders) {
            const element = placeHolders[key];
            let items = []
            element.forEach((el) => {
              items.push({value:'', type:el.value, operator:'', label:el?.label})
            })
            placeHolder.push({label:key, items:items})
        }

        options =  [...options, ...placeHolder]

        if(this.formFields?.schema && Object.keys(this.formFields?.schema)?.length) {
          let payload = {
            label: 'Form Fields',
            items: [],
          }

          // let elem = []
          for (const key in this.formFields?.schema) {
            const element = this.formFields?.schema[key];
            if(!element?.submits && !element?.resets) {
              // if(element?.type == 'radio') {
              //   let index = payload.items.findIndex((el) => el?.elementBuilder?.radioName == element?.radioName)
              //   if(index !== -1) {
              //     payload.items[index]?.elementBuilder.radioValues.push({label:element?.text || '',value:element?.radioValue || ''})
              //   } else {
              //     payload.items.push({value:'', type:element?.name, operator:'', label:element.label ? element.label : element.name, elementBuilder:{...element, radioValues:[{label:element?.text || '',value:element?.radioValue || ''}]} } )
              //   }
              // } else {
                payload.items.push({value:'', type:element?.name, operator:'', label:element.label ? element.label : element.name, elementBuilder:{...element} } )
              // }
            }
          }
          options = [...options, payload]
        }


        options = options.sort((a, b) => a.label.localeCompare(b.label))

        return options.map((group) => {
          const filteredItems = group.items.filter((item) => this.filterAdditionalFilters(item));

          return {
            ...group,
            items: filteredItems.length > 0 ? filteredItems : [], // Return empty array if all items are filtered out
          };
        });
      },
      // Check if undo action is possible
      canUndo() {
        return this.currentIndex > 0;
      },
      // Check if redo action is possible
      canRedo() {
        return this.currentIndex < this.history.length - 1;
      },
      filteredActions() {
        const query = this.searchActionQuery.toLowerCase();
        let actionsCloned = JSON.parse(JSON.stringify(this.actions))
          //if(document.referrer.includes('app.modalsupport.com')) {
           // delete actionsCloned['internal_tools']
          //}
        return Object.keys(actionsCloned).reduce((result, key) => {
          const filtered = actionsCloned[key].filter((action) => action.label.toLowerCase().includes(query) || action.name.toLowerCase().includes(query));
          if (filtered.length > 0) {
            result[key] = filtered;
          }
          return result;
        }, {});
      },
      filteredWorkflows() {
        const query = this.searchTriggerQuery.toLowerCase();
        return Object.keys(this.triggerWorkflows).reduce((result, key) => {
          const filtered = this.triggerWorkflows[key].filter((action) => action.label.toLowerCase().includes(query) || action.name.toLowerCase().includes(query));
          if (filtered.length > 0) {
            result[key] = filtered;
          }
          return result;
        }, {});
      },
    },
    watch: {
      // Watch the note and add it to history when updated
      'selectedData.note': function (newVal) {
        if (this.currentIndex === this.history.length - 1) {
          this.history.push(newVal);
          this.currentIndex++;
        }
      },
      'selectedData.message': function (newVal) {
        if (this.currentIndex === this.history.length - 1) {
          this.history.push(newVal);
          this.currentIndex++;
        }
      },
      'selectedData.prompt': function (newVal) {
        if (this.currentIndex === this.history.length - 1) {
          this.history.push(newVal);
          this.currentIndex++;
        }
      },
      selectedData: {
        deep: true,
        handler() {
          if (this.isFormTouched) {
            let isDisabled = this.checkFilter();
            if (!isDisabled) {
              this.isErrors = false;
              this.showError = false;
              this.isFormTouched = false;
            }
          }
        },
      },
      selectedNode: {
        deep: true,
        handler() {
          if (this.selectedNode) {
            this.singleAssignedUsers = {};
            setTimeout(() => {
              setTooltip(this.$store.state.bootstrap);
            }, 100);
            this.selectedData = JSON.parse(JSON.stringify(this.selectedNode));
            
            if (this.selectedFlow == 'addNewTrigger') {
              let findWorkflowByName = (name) => {
                for (let key in this.triggerWorkflows) {
                  let workflow = this.triggerWorkflows[key].find((item) => item.name === name);
                  if (workflow) {
                    return workflow;
                  }
                }
                return null; // return null if not found
              };
              let payload = findWorkflowByName(this.selectedNode?.name);
              // console.log('payload ', payload)
              if (payload) {
                this.selectedTrigger = { ...payload };
              }
            }
            if (this.selectedNode.name == 'assignTicket') {
              if (!this.selectedNode.roundRobin && this.selectedNode?.assignedUsers?.length) {
                this.singleAssignedUsers = this.selectedNode.assignedUsers[0];
              }
            }
          }
        },
      },
      isOpen() {
        this.searchActionQuery = '';
        this.searchTriggerQuery = '';
      },
    },
  };
</script>
<style>
  .form-scroll .popover-content.inbox-nav-manage-options.mtminus-15 {
    margin-top: -20px;
  }
  .form-scroll .popover-content .popper-search-ico {
    position: absolute;
    left: 15px;
    color: #999;
  }

  .footer-btn button {
    border-radius: 10px !important;
  }

  .footer-btn .save-btn {
    background-color: #1458e0 !important;
  }

  .trigger-header,
  .action-header {
    padding: 1.5rem;
  }

  .trigger-body,
  .action-body {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .trigger-header .title,
  .action-header .title {
    font-weight: 600;
    font-size: 1.125rem;
    text-transform: capitalize;
    text-transform: capitalize;
  }

  .trigger-header .description,
  .action-header .description {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 4px;
  }

  .search-heading {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    font-weight: 700;
  }

  .text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .workflow-group-title,
  .action-group-title {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    font-weight: 600;
  }

  .trigger-body,
  .action-body {
    margin-top: 1rem;
  }

  .workflows-labels,
  .action-labels {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    border-width: 1px;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
  }

  .trigger-icon,
  .action-icon {
    padding: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgba(239, 244, 255, var(--tw-bg-opacity));
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    --tw-text-opacity: 1;
    color: rgba(0, 78, 235, var(--tw-text-opacity));
    cursor: pointer;
  }

  .trigger-title,
  .action-title {
    --tw-text-opacity: 1;
    color: rgb(107, 114, 128);
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0.5rem;
    font-size: 14px;
    line-height: 21px;
  }

  .not-found {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
    line-height: 1.25rem;
    font-size: 0.875rem;
    margin-top: 1.25rem;
  }

  .text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .p-inputicon {
    margin: 0 !important;
    transform: translateY(-50%);
  }
  .p-multiselect-overlay,
  .p-multiselect {
    font-size: 14px !important;
  }
  .p-inputtext, #multiple-ac-2 {
    font-size: 14px !important;
  }

  .p-autocomplete-input-multiple:focus,
  .p-inputtext:enabled:focus,
  .p-textarea:enabled:focus {
    --tw-ring-opacity: 1;
    outline: 2px solid rgba(56, 160, 219, var(--tw-ring-opacity)) !important;
    border-color: transparent !important;
  }

  #note:enabled:focus {
    outline: none !important;
    border-color: none !important;
  }

  #prompt:enabled:focus {
    outline: none !important;
    border-color: none !important;
  }

  #message:enabled:focus {
    outline: none !important;
    border-color: none !important;
  }

  .p-select-list,
  .p-select {
    font-size: 14px !important;
  }
  .action-list label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  body.childAgencyPanelOpen .p-multiselect-overlay .p-multiselect-header .p-checkbox {
    display: none !important;
  }
  .col-11-custom {
    width: calc(100% - 25px) !important;
  }
  .col-1-custom {
    width: 25px !important;
  }
  .text-curious-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(34, 96, 131, var(--tw-text-opacity));
  }
  .p-select-list-container .p-select-option-group {
    background: #000000;
    color: #ffffff;
  }
  .footer-btn button.btn-outline-white {
    background: var(--base-white) !important;
    color: var(--base-black) !important;
    border: 1px solid var(--gray-300) !important;
  }

  .p-select-option.p-select-option-selected {
    background: var(--gray-200) !important;
  }
  .p-select-list-container .p-select-option-group {
    background: var(--gray-200) !important;
    color: var(--gray-800) !important;
    border-radius: 5px !important;
  }
  .p-placeholder {
    color: #334155 !important;
  }
  .input-prompt-tag {
    z-index: 9 !important;
    right: 10px !important;
    top: 10px !important;
    font-size: 11px !important;
    outline: none;
  }
  .form-control:focus {
    box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0.35) !important;
  }

  input[type='checkox'].form-check-input:focus,
  .form-check-input:focus {
    box-shadow: none !important;
    border: 1px solid #e9e9e9;
  }

  .form-check-input:focus {
    box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0) !important;
    border: 1px solid #d5d5d5 !important;
  }
  .form-check-input {
    width: 40px;
    height: 20px;
    background-color: #e9e9e9 !important;
    border-radius: 34px;
    position: relative;
    appearance: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .form-check-input::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 1px 1px 3px 0.05rem rgba(0, 0, 0, 0.1) !important;
    transition: transform 0.3s ease;
  }

  .form-check-input:checked {
    background-color: var(--primary-color);
  }

  .form-check-input:checked::before {
    transform: translateX(20px);
    background-color: #ffffff;
  }
  .form-switch-cf {
    padding-left: 0rem !important;
  }
  .btn-secondary.btn-xs {
    padding: 2px 7px !important;
    border-radius: 5px !important;
  }

  .p-select:not(.p-disabled).p-focus {
    border-color: var(--primary-color) !important;
    outline: 1px solid var(--primary-color) !important;
  }
  .p-select-option:not(.p-select-option-selected):not(.p-disabled).p-focus,
  .p-select-option.p-select-option-selected {
    background: var(--p-select-option-focus-background) !important;
  }
  .p-select-option.p-select-option-selected,
  .p-select-option.p-select-option-selected.p-focus,
  .p-select-option:not(.p-select-option-selected):not(.p-disabled).p-focus {
    color: #111 !important;
  }
  .p-inputtext:enabled:focus,
  .p-textarea:enabled:focus {
    outline: 2px solid var(--primary-color) !important;
  }

  .form-control:focus {
    box-shadow: 0 0 0 0rem #007bff !important;
    border-color: var(--primary-color) !important;
    outline: 1px solid var(--primary-color) !important;
  }
  .p-select-option-group {
    padding: 0 !important;
  }
  .p-select-option-group div.flex {
    padding: 0.5rem 0.75rem;
    --tw-bg-opacity: 1;
    background: rgba(12, 45, 63, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .p-select-option-group .no-data-message {
    background: #ffffff !important;
    font-weight: normal !important;
  }
</style>
