const placeholdersjson = {
    Agent: [
        { label: 'Name', value: '{{agent.name}}', category: 'Agent' },
        { label: 'Email', value: '{{agent.email}}', category: 'Agent' }
      ],
      Company: [
        { label: 'Name', value: '{{company.name}}', category: 'Company' },
        { label: 'Phone', value: '{{company.phone}}', category: 'Company' },
        { label: 'Email', value: '{{company.email}}', category: 'Company' },
        { label: 'Copyright', value: '{{company.copyright}}', category: 'Company' },
        { label: 'Website', value: '{{company.website}}', category: 'Company' },
        { label: 'Widget Url', value: '{{company.widget_url}}', category: 'Company' },
        { label: 'Logo', value: '{{company.logo}}', category: 'Company' },
        { label: 'IOS App Url', value: '{{company.ios}}', category: 'Company' },
        { label: 'Andorid App Url', value: '{{company.android}}', category: 'Company' },
        { label: 'Zapier App Url', value: '{{company.zapier}}', category: 'Company' },
        { label: 'Mobile App Name', value: '{{company.mobileapp_name}}', category: 'Company' },
        { label: 'Zapier App Name', value: '{{company.zapierapp_name}}', category: 'Company' },
      ],
      Contact: [
        { label: 'First Name', value: '{{contact.first_name}}', category:'Contact'},
        { label: 'Last Name', value: '{{contact.last_name}}', category:'Contact'},
        { label: 'Full Name', value: '{{contact.full_name}}', category: 'Contact' },
        { label: 'Email', value: '{{contact.email}}', category:'Contact'},
      ],
      Message: [
        { label: 'Message Subject', value: '{{message.subject}}', category: 'Message' },
        { label: 'Transcript', value: '{{message.transcript}}', category: 'Message' },
        { label: 'Last Message', value: '{{message.last}}', category: 'Message' },
        { label: 'Message Channel', value: '{{message.channel}}', category: 'Message' },
        // { label: 'Sender', value: '{{message.sender}}', category:'Message'},
        { label: 'Sender Full Name', value: '{{message.sender_fullname}}', category:'Message'},
        { label: 'Sender Email', value: '{{message.sender_email}}', category:'Message'},
        { label: 'Sender First Name', value: '{{message.sender_firstname}}', category:'Message'},
        { label: 'Sender Last Name', value: '{{message.sender_lastname}}', category:'Message'},
        { label: 'Start Time', value: '{{message.start_time}}', category:'Message'},
        { label: 'End Time', value: '{{message.end_time}}', category:'Message'},
        { label: 'Duration', value: '{{message.duration}}', category:'Message'},
        { label: 'Queue Duration', value: '{{message.queue_duration}}', category:'Message'},
        { label: 'Conversation Closed By', value: '{{message.closed_by}}', category:'Message'},
        { label: 'CSAT Score', value: '{{message.csat_score}}', category:'Message'},
        { label: 'CSAT Message', value: '{{message.csat_message}}', category:'Message'},
        { label: 'ID', value: '{{message.id}}', category:'Message'},
        { label: 'Starting URL', value: '{{message.starting_url}}', category:'Message'},
        { label: 'Referring URL', value: '{{message.referring_url}}', category:'Message'},
      ],
}

export default placeholdersjson